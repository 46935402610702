import Joi from 'joi';

import { password } from './common';


const schema = Joi.object({
    password,
    repeatPassword: Joi.equal(Joi.ref('password')),
});

export default schema;
