import Joi from 'joi';

import { password } from '../common';


const schema = Joi.object({
    currentPassword: password,
    password,
    repeatPassword: Joi.equal(Joi.ref('password')),
})

export default schema;
