import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaLock } from 'react-icons/fa';
import { joiResolver } from '@hookform/resolvers/joi';
import classnames from 'classnames';
import { MdLockOutline } from 'react-icons/md';

import changePasswordSchema from '../../../../../../validation/profile/change-password';
import axios from 'axios';


function prettyMessage(msg) {
    if (msg) {
        if (msg.startsWith('"currentPassword"')) {
            msg = msg.replace('"currentPassword"', 'Current password');
        }
        else if (msg.startsWith('"password"')) {
            msg = msg.replace('"password"', 'New password');
        }
        else if (msg.startsWith('"repeatPassword"')) {
            msg = msg.replace('"repeatPassword"', 'Password confirmation');
        }
        if (msg.endsWith('[ref:password]')) {
            msg = msg.replace('[ref:password]', 'the same as the new password');
        }

        // Capitalize first letter
        return msg.charAt(0).toUpperCase() + msg.slice(1);
    }
    return msg;
}


export default function ChangePassword() {
    const [invalidCurrentPassword, setInvalidCurrentPassword] = useState(false);

    const { register, watch, handleSubmit, reset, formState: { errors, isSubmitting } } = useForm({
        resolver: joiResolver(changePasswordSchema)
    });

    // clear invalid password message once modified by the user
    const watchCurrentPassword = watch('currentPassword');
    useEffect(() => {
        setInvalidCurrentPassword(false);
    }, [watchCurrentPassword]);

    const onSubmit = async (data) => {
        setInvalidCurrentPassword(false);
        try {
            await axios.put('/api/account/password', {
                currentPassword: data.currentPassword,
                password: data.password
            });

            reset({
                currentPassword: '',
                password: '',
                repeatPassword: ''
            });
        }
        catch (e) {
            const statusCode = e.response?.status;
            if (statusCode === 403) {
                setInvalidCurrentPassword(true);
            }
        }
    }

    return (
        <div className="card">
            <header className="card-header">
                <p className="card-header-title">
                    <span className="icon"><FaLock /></span>
                    Change Password
                </p>
            </header>
            <div className="card-content">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="field is-horizontal">
                        <div className="field-label is-normal">
                            <label className="label">Current password</label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <div className="control has-icons-left">
                                    <input disabled={isSubmitting} type="password" name="password_current" autoComplete="current-password" className="input" {...register('currentPassword')} />
                                    <span className="icon is-small is-left">
                                        <MdLockOutline />
                                    </span>
                                </div>
                                <p className="help is-danger fieldMessage">{prettyMessage(errors.currentPassword?.message) || (invalidCurrentPassword && 'Invalid current password')}</p>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="field is-horizontal">
                        <div className="field-label is-normal">
                            <label className="label">New password</label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <div className="control has-icons-left">
                                    <input disabled={isSubmitting} type="password" autoComplete="new-password" name="password" className="input" {...register('password')} />
                                    <span className="icon is-small is-left">
                                        <MdLockOutline />
                                    </span>
                                </div>
                                <p className="help is-danger fieldMessage">{prettyMessage(errors.password?.message)}</p>
                            </div>
                        </div>
                    </div>
                    <div className="field is-horizontal">
                        <div className="field-label is-normal">
                            <label className="label">Confirm password</label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <div className="control has-icons-left">
                                    <input disabled={isSubmitting} type="password" autoComplete="new-password" name="password_confirmation" className="input" {...register('repeatPassword')} />
                                    <span className="icon is-small is-left">
                                        <MdLockOutline />
                                    </span>
                                </div>
                                <p className="help is-danger fieldMessage">{prettyMessage(errors.repeatPassword?.message)}</p>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="field is-horizontal">
                        <div className="field-label is-normal"></div>
                        <div className="field-body">
                            <div className="field">
                                <div className="control">
                                    <button disabled={isSubmitting} type="submit" className={classnames('button', 'is-primary', { 'is-loading': isSubmitting })}>
                                        Save Changes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}