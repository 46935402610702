import axios from 'axios';
import classnames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { FaDownload, FaExternalLinkAlt, FaSync, FaTrash, FaUpload, FaYoutube } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import ConsolePage from '../../../../../layouts/console';
import { FETCH_JOB_STATE, ITEM_TYPE, TRANSCRIBE_FETCH_JOBS_SAGA } from '../../../../../store/transcribeSlice';
import timeAgo from '../../../../../utils/time-ago';
import DownloadResultModal from './download-result-modal';

import { usePagination } from '../../../../../hooks/pagination';
import DeleteResultModal from './delete-result-modal';
import styles from './jobs.module.scss';

const INVALIDATION_TIME = 60 * 5 * 1000;
const JOB_TIMEOUT = 60 * 60 * 3 * 1000;  // time after which an unfinished job is considered failed

export default function Jobs(props) {
    const dispatch = useDispatch();
    const pastJobs = useSelector(state => state.transcribe.pastJobs);
    const user = useSelector(state => state.user.details);
    const isTableEmpty = pastJobs.jobs.length === 0 && pastJobs.state === FETCH_JOB_STATE.READY;
    const isUserAdmin = user.roles.indexOf('admin') !== -1;

    const { setItems, viewItems, PaginationControl, paginationControlProps } = usePagination(20);
    useEffect(() => {
        setItems(pastJobs.jobs);
    }, [setItems, pastJobs.jobs])

    const [activeItem, setActiveItem] = useState(null);
    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    useEffect(() => {
        if (
            (pastJobs.state === FETCH_JOB_STATE.INITIAL) ||
            (pastJobs.state === FETCH_JOB_STATE.READY && (Date.now() - pastJobs.fetchTimestamp > INVALIDATION_TIME))
        ) {
            dispatch({ type: TRANSCRIBE_FETCH_JOBS_SAGA });
        }
    }, [dispatch, pastJobs]);

    useEffect(() => {
        const hasQueuedJobs = pastJobs.jobs.filter(item => item.status === 'QUEUED').length > 0;
        if (hasQueuedJobs) {
            const intervalId = setInterval(() => {
                dispatch({ type: TRANSCRIBE_FETCH_JOBS_SAGA });
            }, 60 * 1000);  // refresh data every minute if any items are in a queued state
            return () => clearInterval(intervalId);
        }
    }, [dispatch]);

    const formattedTimestamp = useCallback((timestamp) => {
        const date = new Date(timestamp);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    }, []);

    const refresh = useCallback(() => {
        dispatch({ type: TRANSCRIBE_FETCH_JOBS_SAGA });
    }, [dispatch]);

    const getMediaLink = useCallback(async (uploadId) => {
        try {
            const response = await axios.get(`/api/transcribe/mediaLink/${uploadId}`);
            window.location = response.data.url;
        }
        catch { }
    }, []);

    const getStatusColumn = useCallback((status, creationTimestamp) => {
        switch (status) {
            case 'QUEUED':
            case 'PENDING':
                // if the job is not completed after 3 hours, mark it as failed even though the status indicates otherwise
                if (Date.now() - creationTimestamp > JOB_TIMEOUT) {
                    return <span className="has-text-danger">Failed</span>;
                }
                else {
                    return <progress className={classnames('progress', 'is-primary', styles.jobProgressBar)} />;
                }
            case 'SUCCESS':
                return <span>Ready</span>;
            case 'FAIL':
                return <span className="has-text-danger">Failed</span>;
            default:
                return <>{status}</>;
        }
    }, []);

    const getLinkColumn = useCallback((item) => {
        if (item.type === ITEM_TYPE.LINK) {
            return (
                <span>
                    <a href={item.link} target="_blank" rel="noreferrer"><FaExternalLinkAlt className="ml-2" /></a>
                </span>
            )
        }
        else if (isUserAdmin) {
            return (
                <span>
                    <button className="button is-ghost ml-2" onClick={() => getMediaLink(item.uploadId)}><FaDownload /></button>
                </span>
            )
        }

        return null;
    }, [isUserAdmin, getMediaLink]);

    const onShowResult = useCallback((item) => {
        setActiveItem(item);
        setShowDownloadModal(true);
    }, []);

    const onDeleteResult = useCallback((item) => {
        setActiveItem(item);
        setShowDeleteModal(true);
    }, []);

    const showOwner = pastJobs.jobs.some(jobItem => !!jobItem.owner);

    return (
        <>
            <DownloadResultModal show={showDownloadModal} close={() => setShowDownloadModal(false)} item={activeItem} isUserAdmin={isUserAdmin} />
            <DeleteResultModal show={showDeleteModal} close={() => setShowDeleteModal(false)} item={activeItem} refreshJobs={refresh} />
            <ConsolePage levels={['File Transcription', 'Jobs']} widget={<button className="button is-rounded is-small is-primary" onClick={refresh}><FaSync className="mr-2" />Update</button>}>
                <section className="section is-main-section">
                    <div className="container">
                        <div className={classnames('b-table', { 'is-loading': pastJobs.state === FETCH_JOB_STATE.FETCHING })}>
                            <div className="table-wrapper has-mobile-cards">
                                <table className={classnames('table', 'is-fullwidth', styles.jobsTable)}>
                                    <thead>
                                        <tr>
                                            <th className={styles.iconCol}></th>
                                            <th className={styles.submittedCol}>Submitted</th>
                                            {showOwner && <th className={styles.ownerCol}>Owner</th>}
                                            <th className={styles.filenameCol}>Filename/URL</th>
                                            <th className={styles.durationCol}>Length</th>
                                            <th className={styles.statusCol}>Status</th>
                                            <th className={styles.costCol}>Cost</th>
                                            <th>Transcript</th>
                                            <th><FaTrash size={12} /></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {isTableEmpty ? (
                                            <tr className="is-empty">
                                                <td colSpan="5" className="p-6 has-text-centered">
                                                    <p>
                                                        You haven’t submitted any jobs yet. You may create a new job in the <strong><Link to="/transcribe/create" className="is-dotted-link">Create</Link></strong> section.
                                                    </p>
                                                </td>
                                            </tr>
                                        ) : (
                                            viewItems.map((item, i) => {
                                                return (
                                                    <tr key={i} className={classnames({ [styles.deleted]: item.isDeleted })}>
                                                        <td className={classnames(
                                                            { 'has-text-danger': item.type === ITEM_TYPE.LINK },
                                                            { 'has-text-dark': item.type === ITEM_TYPE.UPLOAD }
                                                        )}>{item.type === ITEM_TYPE.LINK ? <FaYoutube /> : <FaUpload />}</td>

                                                        <td data-label="Submitted">
                                                            <span data-tooltip={formattedTimestamp(item.creationTimestamp)} className={classnames('has-tooltip-arrow', styles.tooltip)}>{timeAgo(item.creationTimestamp)}</span>
                                                        </td>

                                                        {showOwner && (
                                                            <td data-label="Owner" className={styles.ownerCol}>
                                                                <span>{item.owner}</span>
                                                            </td>
                                                        )}

                                                        <td className={styles.filenameCol} data-label={item.type === ITEM_TYPE.UPLOAD ? 'Filename' : 'URL'}>
                                                            <span>
                                                                {item.title}
                                                                {getLinkColumn(item)}
                                                            </span>
                                                        </td>

                                                        <td data-label="Length">
                                                            {new Date(item.duration * 1000).toISOString().substr(11, 8)}
                                                        </td>

                                                        <td data-label="Status">
                                                            {getStatusColumn(item.status, item.creationTimestamp)}
                                                        </td>

                                                        <td className={classnames({ [styles.empty]: !item.cost })} data-label="Cost">
                                                            {item.cost ? `€${item.cost.toFixed(2)}` : '--'}
                                                        </td>

                                                        <td data-label="Captions">
                                                            {item.status === 'SUCCESS' && <button className="button is-ghost" disabled={item.isDeleted} onClick={onShowResult.bind(this, item)}><FaDownload /></button>}
                                                        </td>

                                                        <td data-label={item.isDeleted ? '' : 'Delete Transcript'}>
                                                            {item.status === 'SUCCESS' && !item.isDeleted && <button className="button is-ghost" onClick={onDeleteResult.bind(this, item)}><IoMdClose className="has-text-grey-light" /></button>}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="mt-4">
                            <PaginationControl {...paginationControlProps} />
                        </div>
                    </div>
                </section>
            </ConsolePage>
        </>
    )
}