import { useEffect, useState, useRef } from 'react';

import axios from 'axios';
import {
    CardNumberElement, CardCvcElement, CardExpiryElement,
    useElements, useStripe
} from '@stripe/react-stripe-js';


import ProgressBar from '../../../../../../components/progress-bar';
import styles from './payment-details.module.scss';


export default function PaymentDetailsPage({ amount, isProcessingPayment, setIsProcessingPayment, successCb }) {
    const stripe = useStripe();
    const elements = useElements();
    const paymentIntentId = useRef(null);
    const clientSecret = useRef(null);

    const [isFetchingSecret, setIsFetchingSecret] = useState(true);
    const [errorMsg, setErrorMsg] = useState('');
    const [hasSucceeded, setHasSucceeded] = useState(false);

    const STRIPE_ELEMENT_OPTIONS = {
        classes: {
            base: styles.inputBase
        },
        disabled: isProcessingPayment
    };

    useEffect(() => {
        const worker = async () => {
            setIsFetchingSecret(true);
            try {
                const response = await axios.post('/api/billing/payment_intent', { amount })
                paymentIntentId.current = response.data.id;
                clientSecret.current = response.data.clientSecret;
            }
            catch { }
            finally {
                setIsFetchingSecret(false);
            }
        };

        worker();

        return async () => {
            // cancel the intent if user abandoned
            if (!hasSucceeded && paymentIntentId.current) {
                try {
                    await axios.post('/api/billing/payment_intent_cancel', { id: paymentIntentId.current })
                }
                catch { }
            }
        }
    }, [amount, hasSucceeded])

    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrorMsg('');

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsProcessingPayment(true);
        try {
            const result = await stripe.confirmCardPayment(clientSecret.current, {
                payment_method: {
                    card: elements.getElement(CardNumberElement)
                }
            });

            if (result.error) {
                // Show error to your customer (e.g., insufficient funds)
                setErrorMsg(result.error.message);
            }
            else if (result.paymentIntent.status === 'succeeded') {
                setHasSucceeded(true);
                successCb();
            }
            else {
                setErrorMsg('A processing error occurred.');
            }
        }
        catch (e) {
            setErrorMsg('A processing error occurred.');
        }
        finally {
            setIsProcessingPayment(false);
        }
    }

    return isFetchingSecret ? (
        <div className="has-text-centered">
            <ProgressBar style={{ display: 'inline-block', transform: 'scale(0.5)' }} />
        </div>
    ) : (
        <form id="stripe-payment-form" onSubmit={handleSubmit}>
            <div className="field is-horizontal mb-5">
                <div className="field-label is-normal">
                    <label className="label">Amount</label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control">
                            <span className="is-size-4 has-text-primary has-text-weight-medium">
                                €{amount.toFixed(2)}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="field is-horizontal">
                <div className="field-label is-normal">
                    <label className="label">Card No</label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control">
                            <CardNumberElement options={STRIPE_ELEMENT_OPTIONS} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="field is-horizontal">
                <div className="field-label is-normal">
                    <label className="label">CVC</label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control">
                            <CardCvcElement options={STRIPE_ELEMENT_OPTIONS} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="field is-horizontal">
                <div className="field-label is-normal">
                    <label className="label">Expiry</label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control">
                            <CardExpiryElement options={STRIPE_ELEMENT_OPTIONS} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="field is-horizontal">
                <div className="field-label is-normal">
                </div>
                <div className="field-body">
                    <div className="field">
                        <strong>
                            <span className="has-text-danger is-size-7">
                                {errorMsg || <span>&nbsp;</span>}
                            </span>
                        </strong>
                    </div>
                </div>
            </div>
        </form>
    )
}