import { createSlice } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';
import axios from 'axios';

export const LIVE_TRANSCRIBE_FETCH_SESSIONS_SAGA = 'LIVE_TRANSCRIBE_FETCH_SESSIONS_SAGA';

export const FETCH_SESSION_STATE = {
    INITIAL: 'FETCH_SESSION_STATE_INITIAL',
    FETCHING: 'FETCH_SESSION_STATE_FETCHING',
    FAILED: 'FETCH_SESSION_STATE_FAILED',
    READY: 'FETCH_SESSION_STATE_READY'
}


export const liveTranscribeSlice = createSlice({
    name: 'liveTranscribe',
    initialState: {
        pastSessions: {
            fetchTimestamp: 0,
            state: FETCH_SESSION_STATE.INITIAL,
            sessions: []
        }
    },
    reducers: {
        // past sessions
        setSessionFetchingState: (state, action) => {
            state.pastSessions.state = action.payload.state;
        },

        setFetchedSessions: (state, action) => {
            state.pastSessions.fetchTimestamp = Date.now();
            state.pastSessions.sessions = action.payload.sessions;
        }
    }
})

export const { setSessionFetchingState, setFetchedSessions } = liveTranscribeSlice.actions;

export function* fetchSessionsSaga() {
    yield put(setSessionFetchingState({ state: FETCH_SESSION_STATE.FETCHING }));

    try {
        const sessions = yield call(async () => {
            const response = await axios.get('/api/live/sessions');
            return response.data;
        });
        yield put(setFetchedSessions({ sessions }));
        yield put(setSessionFetchingState({ state: FETCH_SESSION_STATE.READY }));
    }
    catch {
        yield put(setSessionFetchingState({ state: FETCH_SESSION_STATE.FAILED }));
    }
}

export default liveTranscribeSlice.reducer;
