import store from '../store/store';

const TRACKING_ID = 'G-2B34Q6M1EV';

const visitDate = new Date();
const events = [];

let initialized = false;


// listen for cookieConsent and enable analytics
store.subscribe(() => {
    const cookieConsent = store.getState().legal.cookieConsent;
    if (!initialized && cookieConsent) {
        enableAnalytics();
    }
});


function initialize() {
    /* eslint-disable */
    window.dataLayer = window.dataLayer || [];
    window.gtag = function () { dataLayer.push(arguments); }
    gtag('js', visitDate);
    gtag('config', TRACKING_ID, { 'anonymize_ip': true, 'allow_google_signals': false });
    for (const eventParams of events) {
        gtag(...eventParams); // send captured events
    }
}

export function disableAnalytics() {
    window[`ga-disable-${TRACKING_ID}`] = true;
    window.gtag = function () {  // capture the events so that they can be sent later if the user gives consent
        events.push(Array.from(arguments));
    };
}

export function enableAnalytics() {
    initialized = true;
    window[`ga-disable-${TRACKING_ID}`] = false;
    initialize();
}

export function trackEvent(event, category) {
    if (process.env.NODE_ENV === 'production') {
        if (category) {
            gtag('event', event, {
                event_category: category
            })
        }
        else {
            gtag('event', event);
        }
    }
    else {
        console.log(`trackEvent: event: ${event}, category: ${category}`);
    }
}

export function trackPage(page) {
    if (process.env.NODE_ENV === 'production') {
        gtag('event', 'page_view', {
            page_path: page
        });
    }
    else {
        console.log(`trackPage: page: ${page}`);
    }
}
