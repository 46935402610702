import Joi from 'joi';
import JoiPasswordComplexity from 'joi-password';


export const email = Joi.string()
    .trim()
    .email({
        tlds: { allow: false }
    })
    .required();

export const password = JoiPasswordComplexity.string()
    .min(8)
    .max(20)
    .minOfLowercase(1)
    .minOfUppercase(1)
    .minOfNumeric(1)
    .required();

export const firstName = Joi.string()
    .min(2)
    .max(20)
    .required();

export const lastName = Joi.string()
    .min(2)
    .max(20)
    .required();
