import { useState, useEffect } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { MdLockOutline } from 'react-icons/md';

import DialogLayout from '../../layouts/dialog/dialog';

import resetPasswordSchema from '../../validation/reset-password';


const VALIDITY_PENDING = 'VALIDITY_PENDING';
const VALIDITY_VALID = 'VALIDITY_VALID';
const VALIDITY_INVALID = 'VALIDITY_INVALID';


function prettyMessage(msg) {
    if (msg) {
        if (msg.startsWith('"password"')) {
            msg = msg.replace('"password"', 'New password');
        }
        else if (msg.startsWith('"repeatPassword"')) {
            msg = msg.replace('"repeatPassword"', 'Password confirmation');
        }
        if (msg.endsWith('[ref:password]')) {
            msg = msg.replace('[ref:password]', 'the same as the new password');
        }

        // Capitalize first letter
        return msg.charAt(0).toUpperCase() + msg.slice(1);
    }
    return msg;
}


export default function ResetPassword() {
    const history = useHistory();
    const { id, token } = useParams();
    const [validityStatus, setValidityStatus] = useState(VALIDITY_PENDING);

    const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm({
        resolver: joiResolver(resetPasswordSchema)
    });

    useEffect(() => {
        const asyncFn = async () => {
            try {
                await axios.post('/api/password/validate-token', {
                    id,
                    token
                });
                setValidityStatus(VALIDITY_VALID);
            }
            catch {
                setValidityStatus(VALIDITY_INVALID);
            }
        }
        asyncFn();
    }, [id, token]);

    const onSubmit = async (data) => {
        try {
            await axios.post('/api/password/reset', {
                id,
                token,
                password: data.password
            });
            history.push('/login');
        }
        catch (e) {
            setValidityStatus(VALIDITY_INVALID);
        }
    };

    const isDisabled = isSubmitting;

    return (
        <DialogLayout>
            <div className="container" style={{ minHeight: '24rem' }}>
                <div className="columns">
                    <div className="column">
                        <h3 className="title is-3">Reset Password</h3>
                        <br />

                        {validityStatus === VALIDITY_INVALID && (
                            <>
                                <article className="message is-danger">
                                    <div className="message-body">
                                        <p className="has-text-weight-medium">Invalid reset link.</p>
                                        <p className="mt-2">Your password reset link is not valid, or has been already used.</p>
                                    </div>
                                </article>

                                <p>
                                    If you still would like to reset your password, request a new one from <Link to="/password/forgot">here</Link>.
                                </p>
                            </>)}

                        {validityStatus === VALIDITY_VALID && (
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="field">
                                    <label className="label" htmlFor="password">New Password</label>
                                    <div className="control has-icons-left">
                                        <input disabled={isDisabled} className="input" name="password" type="password" {...register('password')} />
                                        <span className="icon is-small is-left">
                                            <MdLockOutline />
                                        </span>
                                    </div>
                                    <p className="help is-danger fieldMessage">{prettyMessage(errors.password?.message)}</p>
                                </div>

                                <div className="field">
                                    <label className="label" htmlFor="confirmPassword">Confirm New Password</label>
                                    <div className="control has-icons-left">
                                        <input disabled={isDisabled} className="input" name="confirmPassword" type="password" {...register('repeatPassword')} />
                                        <span className="icon is-small is-left">
                                            <MdLockOutline />
                                        </span>
                                    </div>
                                    <p className="help is-danger fieldMessage">{prettyMessage(errors.repeatPassword?.message)}</p>
                                </div>

                                <button disabled={isDisabled} type="submit" className="button is-block is-primary is-fullwidth is-medium">Change Password</button>
                            </form>
                        )}
                    </div>
                </div>
            </div>
        </DialogLayout>
    );
}