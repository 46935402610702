import Joi from 'joi';

import { firstName, lastName } from '../common';


const schema = Joi.object({
    firstName,
    lastName
})

export default schema;
