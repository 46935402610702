import { useCallback } from 'react';
import { FaTrash, FaExternalLinkAlt, FaYoutube, FaUpload } from 'react-icons/fa';
import classnames from 'classnames';

import { ITEM_TYPE, ITEM_STATE } from '../../../../../../store/transcribeSlice';

import styles from './items-table.module.scss';


export default function ItemsTable(props) {
    const { queue } = props;
    const isTableEmpty = queue.length === 0;

    const getDurationColumn = useCallback((item) => {
        if (item.type === ITEM_TYPE.LINK) {
            return <>{new Date(item.duration * 1000).toISOString().substr(11, 8)}</>
        }
        else {
            if (item.state === ITEM_STATE.UPLOADING) {
                return <progress className={classnames('progress', 'is-success', 'mt-1', styles.progressBar)} value={item.progress} max="1"></progress>
            }
            else if (item.state === ITEM_STATE.PROCESSING) {
                return <progress className={classnames('progress', 'is-success', 'mt-1', styles.progressBar)} max="1"></progress>
            }
            else if (item.state === ITEM_STATE.READY) {
                return <>{new Date(item.duration * 1000).toISOString().substr(11, 8)}</>
            }
            else {
                return <></>
            }
        }
    }, [])

    const getCostColumn = useCallback((item) => {
        const cost = item.cost;
        if (Number.isFinite(cost)) {
            return <>{`€${cost.toFixed(2)}`}</>
        }
        else {
            return null;
        }
    }, [])

    const getStatusColumn = useCallback((item) => {
        let statusText;
        switch (item.state) {
            case ITEM_STATE.QUEUED:
                statusText = 'Queued';
                break;
            case ITEM_STATE.READY:
                statusText = 'Ready';
                break;
            case ITEM_STATE.UPLOADING:
                statusText = 'Uploading';
                break;
            case ITEM_STATE.PROCESSING:
                statusText = 'Processing';
                break;
            default:
                statusText = '';
        }

        return <span>{statusText}</span>
    }, [])

    return (
        <div className="b-table">
            <div className="table-wrapper has-mobile-cards">
                <table className="table is-fullwidth">
                    <thead>
                        <tr>
                            <th className={styles.header1}></th>
                            <th className={classnames({ 'has-text-grey-light': isTableEmpty })}>Filename/URL</th>
                            <th className={classnames(styles.header3, { 'has-text-grey-light': isTableEmpty })}>Status</th>
                            <th className={classnames(styles.header4, { 'has-text-grey-light': isTableEmpty })}>Length</th>
                            <th className={classnames(styles.header5, { 'has-text-grey-light': isTableEmpty })}>Cost</th>
                            <th className={styles.header6}></th>
                        </tr>
                    </thead>

                    <tbody>
                        {isTableEmpty ? (
                            <tr className="is-empty">
                                <td colSpan="5" className="p-6 has-text-centered">
                                    <p>
                                        Start adding clips by either uploading media files from your device or by adding YouTube links. Once you’re done, click the <strong>Transcribe</strong> button to start the transcription process.
                                    </p>
                                </td>
                            </tr>
                        ) : (
                            queue.map((item, i) =>
                                <tr key={i}>
                                    <td className={classnames(
                                        { 'has-text-danger': item.type === ITEM_TYPE.LINK },
                                        { 'has-text-dark': item.type === ITEM_TYPE.UPLOAD }
                                    )}>{item.type === ITEM_TYPE.LINK ? <FaYoutube /> : <FaUpload />}</td>

                                    <td data-label={item.type === ITEM_TYPE.UPLOAD ? 'Filename' : 'URL'}>{item.type === ITEM_TYPE.LINK ? (
                                        <span>
                                            {item.title}
                                            <span className={styles.externalLinkIcon}>
                                                <a href={item.link} target="_blank" rel="noreferrer"><FaExternalLinkAlt className="ml-2" /></a>
                                            </span>
                                        </span>
                                    ) : <>{item.title}</>}</td>

                                    <td data-label="Status">
                                        {getStatusColumn(item)}
                                    </td>

                                    <td data-label="Length">
                                        {getDurationColumn(item)}
                                    </td>

                                    <td data-label="Cost">
                                        {getCostColumn(item)}
                                    </td>

                                    <td>
                                        <span>
                                            <a href='#/' className='has-text-grey-light' onClick={props.onRemove.bind(this, item)}><FaTrash className="mr-2" />
                                                <span className={styles.remove}>Remove</span>
                                            </a>
                                        </span>
                                    </td>
                                </tr>)
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}