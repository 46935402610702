import { FaAt } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import classnames from 'classnames';
import axios from 'axios';
import Joi from 'joi';


import { email } from '../../../../../../validation/common';
import { useCallback, useEffect, useState } from 'react';


const MsgType = Object.freeze({
    None: 0,
    Success: 1,
    Error: 2
});


function prettyMessage(msg) {
    if (msg) {
        if (msg.startsWith('"email"')) {
            msg = msg.replace('"email"', 'Email');
        }

        // Capitalize first letter
        return msg.charAt(0).toUpperCase() + msg.slice(1);
    }
    return msg;
}


export default function Notifications() {
    const [resultMsg, setResultMsg] = useState(MsgType.None);

    const { register, handleSubmit, formState: { errors, isSubmitting }, reset } = useForm({
        resolver: joiResolver(Joi.object({
            email
        }))
    });

    const onSubmit = useCallback(async (data) => {
        setResultMsg(MsgType.None);
        try {
            await axios.put('/api/admin/invite', data);
            reset();
            setResultMsg(MsgType.Success);
        }
        catch (e) {
            setResultMsg(MsgType.Error);
        }
    }, [setResultMsg, reset])

    return (
        <div className="tile is-ancestor">
            <div className="tile is-parent">
                <div className="card tile is-child">
                    <header className="card-header">
                        <p className="card-header-title">
                            <span className="icon"><FaAt /></span>
                            Registration Invite
                        </p>
                    </header>
                    <div className="card-content">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">E-mail</label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <div className="control">
                                            <input type="email" name="email" className="input" {...register('email')} onChange={() => setResultMsg(MsgType.None)} />
                                        </div>
                                        <p className="help is-danger fieldMessage">{prettyMessage(errors.email?.message)}</p>
                                        {resultMsg !== MsgType.None && (
                                            resultMsg === MsgType.Success ? (
                                                <p className="help is-success fieldMessage">Invite link sent successfully.</p>
                                            ) : (
                                                <p className="help is-danger fieldMessage">Invite link could not be sent.</p>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="field is-horizontal">
                                <div className="field-label is-normal"></div>
                                <div className="field-body">
                                    <div className="field">
                                        <div className="control">
                                            <button disabled={isSubmitting} type="submit" className={classnames('button', 'is-primary', { 'is-loading': isSubmitting })}>
                                                Send Invite
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}