import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { LOGGED_IN } from '../store/userSlice';


const LoggedOutRoute = ({ component: Component, auth, ...rest }) => {
    const loginState = useSelector((state) => state.user.loginState);

    return (
        <Route {...rest} render={(props) => (
            loginState === LOGGED_IN
                ? <Redirect to='/' />
                : <Component {...props} />
        )} />
    )
}

export default LoggedOutRoute;
