import { useEffect } from 'react';
import classnames from 'classnames';

import { useModalNoScroll } from '../../../../../../hooks/modal-noscroll';


export default function InsufficientBalanceModal({ show, close, billing, availableBalance, cost }) {
    const setModalActive = useModalNoScroll();
    useEffect(() => {
      setModalActive(show);
    }, [setModalActive, show]);

    return (
        <div className={classnames('modal', 'modal-fx-slideTop', { 'is-active': show })}>
            <div className="modal-background"></div>
            <div className="modal-content">
                <header className="modal-card-head">
                    <p className="modal-card-title">Insufficient Balance</p>
                    <button className="delete" aria-label="close" onClick={close}></button>
                </header>
                <section className="modal-card-body">
                    <p>
                        Your available balance is <strong>€{availableBalance?.toFixed(2)}</strong> but the total cost to process your job(s) is <strong>€{cost?.toFixed(2)}</strong>. Before you may proceed with the transcription process, you must top-up your balance.
                    </p>
                </section>
                <footer className="modal-card-foot is-justify-content-flex-end">
                    <button className="button is-primary" onClick={billing}>Top-up</button>
                </footer>
            </div>
        </div>
    )
}
