import { takeEvery, fork } from 'redux-saga/effects';

import {
    LIVE_TRANSCRIBE_FETCH_SESSIONS_SAGA, fetchSessionsSaga
} from './liveTranscribeSlice';

import {
    addLinkItemSaga, addUploadItemSaga,
    TRANSCRIBE_ADD_LINK_ITEM_SAGA, TRANSCRIBE_ADD_UPLOAD_ITEM_SAGA,
    TRANSCRIBE_FETCH_JOBS_SAGA,
    fetchJobsSaga
} from './transcribeSlice';

import {
    fetchUserSaga, logoutSaga,
    FETCH_USER_SAGA, USER_LOGOUT_SAGA
} from './userSlice';


export default function* rootSaga() {
    yield takeEvery(FETCH_USER_SAGA, fetchUserSaga);
    yield takeEvery(USER_LOGOUT_SAGA, logoutSaga);
    
    yield takeEvery(LIVE_TRANSCRIBE_FETCH_SESSIONS_SAGA, fetchSessionsSaga);

    yield takeEvery(TRANSCRIBE_ADD_LINK_ITEM_SAGA, addLinkItemSaga);
    yield takeEvery(TRANSCRIBE_FETCH_JOBS_SAGA, fetchJobsSaga);
    yield fork(addUploadItemSaga, TRANSCRIBE_ADD_UPLOAD_ITEM_SAGA);
}
