import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import userReducer from './userSlice';
import liveTranscribeSlice from './liveTranscribeSlice';
import transcribeReducer from './transcribeSlice';
import dashboardReducer from './dashboardSlice';
import billingReducer from './billingSlice';
import legalReducer from './legalSlice';
import createSagaMiddleware from 'redux-saga';

import saga from './saga';

const sagaMiddleware = createSagaMiddleware();

const appReducer = combineReducers({
    user: userReducer,
    legal: legalReducer,
    liveTranscribe: liveTranscribeSlice,
    transcribe: transcribeReducer,
    dashboard: dashboardReducer,
    billing: billingReducer
});

const store = configureStore({
    reducer: (state, action) => {
        if (action.type === 'reset/store') {
            return appReducer(undefined, action);
        }

        return appReducer(state, action);
    },
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [
        ...getDefaultMiddleware({ serializableCheck: false }),
        sagaMiddleware
    ]
})

sagaMiddleware.run(saga);

export default store;
