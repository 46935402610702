import Joi from 'joi';

import { email } from './common';


const schema = Joi.object({
    email
})

export default schema;
