import { createSlice } from '@reduxjs/toolkit';
import { call, take, put, select, actionChannel, fork, delay } from 'redux-saga/effects';
import { eventChannel, END } from 'redux-saga';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';


export const TRANSCRIBE_ADD_LINK_ITEM_SAGA = 'TRANSCRIBE_ADD_LINK_ITEM_SAGA';
export const TRANSCRIBE_ADD_UPLOAD_ITEM_SAGA = 'TRANSCRIBE_ADD_UPLOAD_ITEM_SAGA';
export const TRANSCRIBE_REMOVE_ITEM_SAGA = 'TRANSCRIBE_REMOVE_ITEM_SAGA';
export const TRANSCRIBE_FETCH_JOBS_SAGA = 'TRANSCRIBE_FETCH_JOBS_SAGA';

export const ITEM_TYPE = {
    LINK: 'LINK',
    UPLOAD: 'UPLOAD'
}

export const ITEM_STATE = {
    QUEUED: 'ITEM_STATE_QUEUED',
    UPLOADING: 'ITEM_STATE_UPLOADING',
    PROCESSING: 'ITEM_STATE_PROCESSING',
    READY: 'ITEM_STATE_READY',
    FAILED: 'ITEM_STATE_FAILED'
}

export const FETCH_JOB_STATE = {
    INITIAL: 'FETCH_JOB_STATE_INITIAL',
    FETCHING: 'FETCH_JOB_STATE_FETCHING',
    FAILED: 'FETCH_JOB_STATE_FAILED',
    READY: 'FETCH_JOB_STATE_READY'
}


function isUploadAlreadyQueued(queue, fileMetadata) {
    return queue
        .filter(item => item.type === ITEM_TYPE.UPLOAD)
        .findIndex(
            item => {
                // compare file attributes
                return (
                    (item.fileMetadata.name === fileMetadata.name) &&
                    (item.fileMetadata.size === fileMetadata.size) &&
                    (item.fileMetadata.lastModified === fileMetadata.lastModified)
                )
            }
        ) !== -1;
}


export const transcribeSlice = createSlice({
    name: 'transcribe',
    initialState: {
        createQueue: [],
        pastJobs: {
            fetchTimestamp: 0,
            state: FETCH_JOB_STATE.INITIAL,
            jobs: []
        }
    },
    reducers: {
        // create job
        addLinkItem: (state, action) => {
            const newItem = {
                type: ITEM_TYPE.LINK,
                ...action.payload,
                state: ITEM_STATE.READY
            };

            state.createQueue.push(newItem);
        },

        removeItem: (state, action) => {
            const index = state.createQueue.findIndex(item => item.id === action.payload.id);
            if (index !== -1 && state.createQueue[index].state !== ITEM_STATE.UPLOADING) {
                state.createQueue = state.createQueue.filter(item => item.id !== action.payload.id);
            }
        },

        queueUploadItem: (state, action) => {

            const { file } = action.payload;

            const fileMetadata = {
                name: file.name,
                size: file.size,
                lastModified: file.lastModified
            }

            // only add if an identical one is not already queue
            if (isUploadAlreadyQueued(state.createQueue, fileMetadata)) {
                return;
            }

            const newItem = {
                type: ITEM_TYPE.UPLOAD,
                id: uuidv4(),
                ...action.payload,
                fileMetadata,
                state: ITEM_STATE.QUEUED
            }

            state.createQueue.push(newItem);
        },

        setUploadItemUploadData: (state, action) => {
            const index = state.createQueue.findIndex(item => item.type === ITEM_TYPE.UPLOAD && item.id === action.payload.id);
            if (index !== -1) {
                state.createQueue[index].state = ITEM_STATE.UPLOADING;
                state.createQueue[index].uploadId = action.payload.uploadId;
                state.createQueue[index].cancelToken = action.payload.cancelToken;
            }
        },

        setItemUploadProgress: (state, action) => {
            const index = state.createQueue.findIndex(item => item.type === ITEM_TYPE.UPLOAD && item.id === action.payload.id);
            if (index !== -1) {
                state.createQueue[index].progress = action.payload.progress;
            }
        },

        setItemState: (state, action) => {
            const index = state.createQueue.findIndex(item => item.type === ITEM_TYPE.UPLOAD && item.id === action.payload.id);
            if (index !== -1) {
                state.createQueue[index].state = action.payload.state;
            }
        },

        setItemDuration: (state, action) => {
            const index = state.createQueue.findIndex(item => item.type === ITEM_TYPE.UPLOAD && item.id === action.payload.id);
            if (index !== -1) {
                state.createQueue[index].duration = action.payload.duration;
                state.createQueue[index].cost = action.payload.cost;
                state.createQueue[index].state = ITEM_STATE.READY;
            }
        },

        clearItems: (state, action) => {
            state.createQueue = [];
        },

        // past jobs
        setJobFetchingState: (state, action) => {
            state.pastJobs.state = action.payload.state;
        },

        setFetchedJobs: (state, action) => {
            state.pastJobs.fetchTimestamp = Date.now();
            state.pastJobs.jobs = action.payload.jobs;
        }
    }
})

export const {
    addLinkItem, queueUploadItem, setUploadItemUploadData, setItemDuration, setItemState, setItemUploadProgress, removeItem, clearItems,
    setJobFetchingState, setFetchedJobs } = transcribeSlice.actions


export function* addLinkItemSaga(action) {
    // ensure has not already been added
    const queue = yield select(s => s.transcribe.createQueue);
    if (queue.findIndex(item => {
        return (item.type === ITEM_TYPE.LINK) && (item.link === action.payload.link);
    }) !== -1) {
        return;
    }

    yield put(addLinkItem({
        id: uuidv4(),
        ...action.payload
    }));
}

function* fetchUploadedFileDuration(payload) {
    while (true) {
        yield delay(2000);
        try {
            const response = yield call(async () => {
                const response = await axios.get(`/api/transcribe/duration/upload?id=${payload.uploadId}`);
                return response.data;
            });

            if (!response.pending) {
                yield put(setItemDuration({
                    id: payload.id,
                    duration: response.duration,
                    cost: response.cost
                }));
                break;
            }
        }
        catch (e) {
            yield put(removeItem({ id: payload.id }));
            break;

        }
    }
}

function* uploadProgressWatcher(channel) {
    while (true) {
        const data = yield take(channel);
        yield put(setItemUploadProgress({ ...data }));
    }
}

export function* addUploadItemSaga() {
    const channel = yield actionChannel(TRANSCRIBE_ADD_UPLOAD_ITEM_SAGA);
    while (true) {
        const action = yield take(channel);

        // check that the item is still in the queue
        let queue = yield select(s => s.transcribe.createQueue);
        const item = queue.find(item => item.type === ITEM_TYPE.UPLOAD && item.file === action.file);
        if (!item) {  // item has been removed, so stop here
            continue;
        }

        // request a pre-signed url
        let preSignResponse;
        try {
            preSignResponse = yield call(async () => {
                const response = await axios.get('/api/transcribe/upload/file');
                return response.data;
            })
        }
        catch {
            yield put(removeItem(item.id));
            continue;
        }

        const { uploadId, uploadUrl } = preSignResponse;

        // start uploading
        let progressEmitter;
        const progressChannel = eventChannel((emitter) => {
            progressEmitter = emitter;
            return () => { };
        });

        yield fork(uploadProgressWatcher, progressChannel);

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        yield put(setUploadItemUploadData({
            id: item.id,
            cancelToken: source,
            uploadId
        }));

        try {
            yield call(async () => {
                await axios.put(uploadUrl, item.file, {
                    headers: {
                        'Content-Type': item.file.type
                    },
                    cancelToken: source.token,
                    onUploadProgress: progressEvent => {
                        const progress = progressEvent.loaded / progressEvent.total;
                        progressEmitter({
                            id: item.id,
                            progress
                        });
                    }
                });

                progressEmitter(END);
            })
        }
        catch (e) {
            yield put(setItemState({ id: item.id, state: ITEM_STATE.FAILED }));
            if (axios.isCancel(e)) {
                yield put(removeItem({ id: item.id }));
                continue;
            }
            else {
                yield put(removeItem({ id: item.id }));
                continue;
            }
        }

        yield put(setItemState({ id: item.id, state: ITEM_STATE.PROCESSING }));
        yield fork(fetchUploadedFileDuration, { id: item.id, uploadId });
    }
}

export function* fetchJobsSaga() {
    yield put(setJobFetchingState({ state: FETCH_JOB_STATE.FETCHING }));

    try {
        const jobs = yield call(async () => {
            const response = await axios.get('/api/transcribe/jobs');
            return response.data;
        });
        yield put(setFetchedJobs({ jobs }));
        yield put(setJobFetchingState({ state: FETCH_JOB_STATE.READY }));
    }
    catch {
        yield put(setJobFetchingState({ state: FETCH_JOB_STATE.FAILED }));
    }
}

export default transcribeSlice.reducer
