import { useForm } from 'react-hook-form';
import { FaUser } from 'react-icons/fa';
import { joiResolver } from '@hookform/resolvers/joi';
import { useSelector, useDispatch } from 'react-redux';
import { MdMailOutline, MdPersonOutline } from 'react-icons/md';
import classnames from 'classnames';
import axios from 'axios';

import changeProfileSchema from '../../../../../../validation/profile/edit-profile';
import { fetchUser } from '../../../../../../store/userSlice';


function prettyMessage(msg) {
    if (msg) {
        if (msg.startsWith('"firstName"')) {
            msg = msg.replace('"firstName"', 'First name');
        }
        else if (msg.startsWith('"lastName"')) {
            msg = msg.replace('"lastName"', 'Last name');
        }

        // Capitalize first letter
        return msg.charAt(0).toUpperCase() + msg.slice(1);
    }
    return msg;
}


export default function ChangeProfile() {
    const dispatch = useDispatch();
    const userDetails = useSelector((state) => state.user.details);

    const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm({
        resolver: joiResolver(changeProfileSchema)
    });

    const onSubmit = async (data) => {
        try {
            await axios.put('/api/account/profile', data);
            await dispatch(fetchUser());
        }
        catch {
        }
    }

    return (
        <div className="card">
            <header className="card-header">
                <p className="card-header-title">
                    <span className="icon"><FaUser /></span>
                    Edit Profile
                </p>
            </header>
            <div className="card-content">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="field is-horizontal">
                        <div className="field-label is-normal">
                            <label className="label">E-mail</label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <div className="control has-icons-left">
                                    <input disabled type="email" name="email" defaultValue={userDetails.email} className="input" />
                                    <span className="icon is-small is-left">
                                        <MdMailOutline />
                                    </span>
                                </div>
                                <p className="help is-danger fieldMessage"></p>
                            </div>
                        </div>
                    </div>
                    <div className="field is-horizontal">
                        <div className="field-label is-normal">
                            <label className="label">First Name</label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <div className="control has-icons-left">
                                    <input disabled={isSubmitting} type="text" name="name" defaultValue={userDetails.firstName} className="input" {...register('firstName')} />
                                    <span className="icon is-small is-left">
                                        <MdPersonOutline />
                                    </span>
                                </div>
                                <p className="help is-danger fieldMessage">{prettyMessage(errors.firstName?.message)}</p>
                            </div>
                        </div>
                    </div>
                    <div className="field is-horizontal">
                        <div className="field-label is-normal">
                            <label className="label">Last Name</label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <div className="control has-icons-left">
                                    <input disabled={isSubmitting} type="text" name="name" defaultValue={userDetails.lastName} className="input" {...register('lastName')} />
                                    <span className="icon is-small is-left">
                                        <MdPersonOutline />
                                    </span>
                                </div>
                                <p className="help is-danger fieldMessage">{prettyMessage(errors.lastName?.message)}</p>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="field is-horizontal">
                        <div className="field-label is-normal"></div>
                        <div className="field-body">
                            <div className="field">
                                <div className="control">
                                    <button disabled={isSubmitting} type="submit" className={classnames('button', 'is-primary', { 'is-loading': isSubmitting })}>
                                        Save Changes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}