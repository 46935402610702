import { useEffect } from 'react';
import { useHistory } from 'react-router';


export default function ScrollToTop() {
    const history = useHistory();

    useEffect(() => {
        if (history.action !== 'POP') {
            window.scrollTo(0, 0);
        }
    }, [history.location]);

    return null;
}
