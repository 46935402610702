import { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import BillingModal from './billing-modal/billing-modal';
import classnames from 'classnames';
import { FaSync, FaCreditCard } from 'react-icons/fa';

import ConsolePage from '../../../../../layouts/console';
import { getBilling, LoadingState } from '../../../../../store/billingSlice';


import styles from './manage.module.scss';

function formatTimestamp(timestamp) {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(timestamp).toLocaleTimeString(undefined, options);
}


const currencyFormatter = new Intl.NumberFormat('mt-MT', {
    style: 'currency',
    currency: 'EUR',
});


export default function Manage(props) {
    const [showBilling, setShowBilling] = useState(false);

    const dispatch = useDispatch();
    const billing = useSelector((state) => state.billing);
    const { loadingState, availableBalance } = billing;

    const refreshClicked = useCallback(() => {
        dispatch(getBilling());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getBilling());
    }, [dispatch]);

    const isTableEmpty = billing.transactions?.length === 0 && loadingState === LoadingState.Loaded;

    return (
        <>
            <BillingModal show={showBilling} close={() => setShowBilling(false)} updateBalanceCb={() => dispatch(getBilling())} />

            <ConsolePage levels={['Billing', 'Manage']} widget={<button className="button is-rounded is-small is-primary" onClick={refreshClicked}><FaSync className="mr-2" />Update</button>}>
                <section className="section is-main-section">
                    <div className="container">
                        <div className="tile is-ancestor">
                            <div className="tile is-parent">
                                <div className="card tile is-child">
                                    <header className="card-header">
                                        <p className="card-header-title">
                                            <span className="icon"><FaCreditCard />
                                            </span>Account Credits
                                        </p>
                                    </header>
                                    <div className="card-content">
                                        {loadingState !== LoadingState.Loaded ? (
                                            <div className={(classnames('ph-item', styles.placeholder))}>
                                                <div className="ph-col-12">
                                                    <div className="ph-row">
                                                        <div className="ph-col-8 big" />
                                                        <div className="ph-col-4 empty" />
                                                        <div className={(classnames('ph-col-2', styles.largeText))} />
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="field">
                                                <div className="control">
                                                    <p>
                                                        Account credits that are consumed when submitting processing jobs.
                                                    </p>
                                                    <h1 className="mt-4 is-size-2 has-text-primary">
                                                        {currencyFormatter.format(availableBalance)}
                                                    </h1>
                                                </div>
                                            </div>
                                        )}
                                        <hr />
                                        <div className="field">
                                            <div className="control">
                                                <button className="button is-primary" onClick={() => setShowBilling(true)}>Add Credit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={classnames('b-table', { 'is-loading': loadingState === LoadingState.Pending })}>
                            <div className="table-wrapper has-mobile-cards">
                                <table className={classnames('table', 'is-fullwidth', styles.transactionsTable)}>
                                    <thead>
                                        <tr>
                                            <th className={styles.timestampCol}>Date</th>
                                            <th className={styles.typeCol}>Description</th>
                                            <th className={styles.amountCol}>Amount</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {isTableEmpty ? (
                                            <tr className="is-empty">
                                                <td colSpan="3" className="p-6 has-text-centered">
                                                    <p>
                                                        No transactions.
                                                    </p>
                                                </td>
                                            </tr>
                                        ) : (
                                            billing?.transactions?.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td data-label="Date" className={styles.timestampCol}>
                                                            {formatTimestamp(item.timestamp)}
                                                        </td>
                                                        <td data-label="Type" className={styles.typeCol}>{item.description}</td>
                                                        <td data-label="Amount">{currencyFormatter.format(item.amount)}</td>
                                                    </tr>
                                                )
                                            })
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </ConsolePage>
        </>
    )
}