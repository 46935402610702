import { useEffect, useState, useRef, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';

const modalsState = {
}


function updateClassState(modalsState) {
    const isShowing = Object.values(modalsState).some((v) => v);
    if (isShowing) {
        window.document.querySelector('html').classList.add('modal-open');
    }
    else {
        window.document.querySelector('html').classList.remove('modal-open');
    }
}

export function useModalNoScroll() {
    const modalId = useRef(uuidv4());
    const [isShowing, setIsShowing] = useState(false);

    useEffect(() => {
        if (!modalsState[modalId.current]) {
            modalsState[modalId.current] = false;
        }
        modalsState[modalId.current] = isShowing;
        updateClassState(modalsState);

        const id = modalId.current;
        return () => {
            delete modalsState[id];
            updateClassState(modalsState);
        }
    }, [isShowing]);

    const setter = useCallback((active) => {
        setIsShowing(active);
    }, [setIsShowing]);

    return setter;
}
