import ConsolePage from '../../../../../layouts/console';

import ChangeProfile from './components/change-profile';
import ChangePassword from './components/change-password';


export default function Profile(props) {
    return (
        <ConsolePage levels={['Account', 'Profile']}>
            <section className="section is-main-section">
                <div className="container">
                    <ChangeProfile />
                    <ChangePassword />
                </div>
            </section>
        </ConsolePage>
    )
}
