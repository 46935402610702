import classNames from 'classnames';
import styles from './stat-card.module.scss';


function CardPlaceholder() {
    return (
        <div className={(classNames('ph-item', styles.placeholder))}>
            <div className="ph-col-12">
                <div className={classNames('ph-row', styles.valueRow)}>
                    <div className="ph-col-4 empty" />
                    <div className="ph-col-4 ph-avatar" />
                    <div className="ph-col-4 empty" />
                </div>
                <div className="ph-row">
                    <div className="ph-col-12 big" />
                    <div className="ph-col-4 big" />
                    <div className="ph-col-8 empty big" />
                </div>
            </div>
        </div>
    )
}


export default function StatCard({ isLoading, icon, title, value, valueColor, text }) {
    return (
        <div className="tile is-parent">
            <div className="card tile is-child">
                <header className="card-header">
                    <p className="card-header-title">
                        <span className="icon">
                            {icon}
                        </span>{title}
                    </p>
                </header>
                <div className="card-content">
                    {isLoading ?
                        <CardPlaceholder /> : (
                            <div className="is-flex is-flex-direction-column is-align-items-center">
                                <h1 className={classNames('is-align-items-start mr-4 pl-2 pr-2', styles.valueText)} style={{ color: valueColor }}>
                                    {value}
                                </h1>
                                <p className="is-size-4 mt-1 has-text-centered">{text}</p>
                            </div>
                        )}
                </div>
            </div>
        </div>
    )
}
