import { useCallback, useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import { joiResolver } from '@hookform/resolvers/joi';
import { useDispatch } from 'react-redux';
import { MdMailOutline, MdLockOutline, MdPersonOutline } from 'react-icons/md';
import classnames from 'classnames';

import { FaCommentDots } from 'react-icons/fa';
import LegalDocuments from '../../enums/LegalDocuments';
import { FETCH_USER_SAGA } from '../../store/userSlice';
import { setCurrentLegalDocument } from '../../store/legalSlice';
import registrationSchema from '../../validation/registration';
import SplitLayout from '../../layouts/split/split';

import styles from './register.module.scss';


function prettyMessage(msg) {
    if (msg) {
        if (msg.startsWith('"firstName"')) {
            msg = msg.replace('"firstName"', 'First name');
        }
        else if (msg.startsWith('"lastName"')) {
            msg = msg.replace('"lastName"', 'Last name');
        }
        else if (msg.startsWith('"email"')) {
            msg = msg.replace('"email"', 'Email');
        }
        else if (msg.startsWith('"password"')) {
            msg = msg.replace('"password"', 'New password');
        }
        else if (msg.startsWith('"repeatPassword"')) {
            msg = msg.replace('"repeatPassword"', 'Password confirmation');
        }
        if (msg.endsWith('[ref:password]')) {
            msg = msg.replace('[ref:password]', 'the same as the password');
        }

        // Capitalize first letter
        return msg.charAt(0).toUpperCase() + msg.slice(1);
    }
    return msg;
}


export default function Register() {
    const [agreesLegal, setAgreesLegal] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const dispatch = useDispatch();

    const { inviteCode } = useParams();

    const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm({
        resolver: joiResolver(registrationSchema)
    });

    const openPolicy = useCallback((e, document) => {
        dispatch(setCurrentLegalDocument(document));
        e.preventDefault();
    }, [dispatch]);

    const onSubmit = async (data) => {
        try {
            await axios.post('/api/register', {
                firstName: data.firstName,
                lastName: data.lastName,
                username: data.email,
                password: data.password,
                inviteCode: inviteCode
            });

            setIsLoggedIn(true);
            dispatch({ type: FETCH_USER_SAGA });
        }
        catch (e) {
            const statusCode = e.response?.status;
            setShowErrorMessage(true);
            if (statusCode === 409) {
                setErrorMessage(<>An account using that <strong>Email</strong> is already registered.</>);
            }
            else {
                setErrorMessage(<>There was a problem registering your account.</>);
            }
        }
    }

    const isDisabled = isSubmitting || isLoggedIn;

    return (
        <SplitLayout>
            <div className={classnames({ [styles.blur]: !inviteCode })}>
                <h3 className="title is-3">Create an Account</h3>
                <p>Create an account to get access to our tools.</p>
                <br />

                {showErrorMessage && (
                    <article className="message is-danger">
                        <div className="message-body">
                            {errorMessage}
                        </div>
                    </article>)}

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="field is-horizontal">
                        <div className="field-body">
                            <div className="field" style={{ width: "100%" }}>
                                <label className="label" htmlFor="firstName">First name</label>
                                <div className="control has-icons-left">
                                    <input disabled={isDisabled} className="input" name="firstName" type="text" {...register('firstName')} />
                                    <span className="icon is-small is-left">
                                        <MdPersonOutline />
                                    </span>
                                </div>
                                <p className="help is-danger fieldMessageDouble">{prettyMessage(errors.firstName?.message)}</p>
                            </div>

                            <div className="field" style={{ width: "100%" }}>
                                <label className="label" htmlFor="lastName">Last name</label>
                                <div className="control has-icons-left">
                                    <input disabled={isDisabled} className="input" name="lastName" type="text" {...register('lastName')} />
                                    <span className="icon is-small is-left">
                                        <MdPersonOutline />
                                    </span>
                                </div>
                                <p className="help is-danger fieldMessageDouble">{prettyMessage(errors.lastName?.message)}</p>
                            </div>
                        </div>
                    </div>

                    <div className="field">
                        <label className="label" htmlFor="email">Email</label>
                        <div className="control has-icons-left">
                            <input disabled={isDisabled} className="input" name="email" type="text" {...register('email')} />
                            <span className="icon is-small is-left">
                                <MdMailOutline />
                            </span>
                        </div>
                        <p className="help is-danger fieldMessage">{prettyMessage(errors.email?.message)}</p>
                    </div>

                    <div className="field">
                        <label className="label" htmlFor="password">Password</label>
                        <div className="control has-icons-left">
                            <input disabled={isDisabled} className="input" name="password" type="password" {...register('password')} />
                            <span className="icon is-small is-left">
                                <MdLockOutline />
                            </span>
                        </div>
                        <p className="help is-danger fieldMessage">{prettyMessage(errors.password?.message)}</p>
                    </div>

                    <div className="field">
                        <label className="label" htmlFor="confirmPassword">Confirm Password</label>
                        <div className="control has-icons-left">
                            <input disabled={isDisabled} className="input" name="confirmPassword" type="password" {...register('repeatPassword')} />
                            <span className="icon is-small is-left">
                                <MdLockOutline />
                            </span>
                        </div>
                        <p className="help is-danger fieldMessage">{prettyMessage(errors.repeatPassword?.message)}</p>
                    </div>

                    <div className="field">
                        <label className="checkbox">
                            <input type="checkbox" checked={agreesLegal} onChange={(e) => setAgreesLegal(e.target.checked)} />
                            <span className="ml-2 is-unselectable is-size-6">I Agree to the <button className="button is-ghost" onClick={(e) => openPolicy(e, LegalDocuments.PrivacyPolicy)}>Privacy Policy</button> and <button className="button is-ghost" onClick={(e) => openPolicy(e, LegalDocuments.TermsOfUse)}>Terms of Use</button></span>
                        </label>
                    </div>

                    <button disabled={isDisabled || !agreesLegal} type="submit" className="button is-block is-primary is-fullwidth is-medium mt-5">Register</button>
                    <br />
                    <p className="description">Already have an account? <Link to="/login">Sign in</Link></p>
                </form>
            </div>

            {!inviteCode && (
                <div className={classnames([styles.overlay], 'p-6')}>
                    <div className={classnames('mt-5 mb-6 is-block ml-auto mr-auto', styles.registerIcon)} />
                    <h3 className={classnames('title', 'is-3', styles.inviteTitle)}>Request an <span className={styles.noBreak}><span className={styles.titleHighlight}>Invitation Code</span>.</span></h3>
                    <p className="mt-6">Registration currently requires first obtaining an invitation code.</p>
                    <p className="mt-4">If you believe that you may benefit from using our services, please write to us on <strong><a href="mailto:info@bilmalti.com">info@bilmalti.com</a></strong> or contact us through the <strong className="has-text-primary"><span className={styles.noBreak}>Live Chat <FaCommentDots /></span></strong> at the bottom of the page.</p>
                    <p className="mt-4">We'll very gladly send you an invitation code if our services are a good match to your needs.</p>

                    <div className="mt-6">
                        <Link to="/login" className="mt-6">← Go back to sign-in</Link>
                    </div>
                </div>
            )}
        </SplitLayout>
    );
}