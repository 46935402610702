import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { FaSync, FaMicrophone } from 'react-icons/fa';

import ConsolePage from '../../../../../layouts/console';
import timeAgo from '../../../../../utils/time-ago';

import styles from './sessions.module.scss';
import { FETCH_SESSION_STATE, LIVE_TRANSCRIBE_FETCH_SESSIONS_SAGA } from '../../../../../store/liveTranscribeSlice';
import { usePagination } from '../../../../../hooks/pagination';

const INVALIDATION_TIME = 60 * 5 * 1000;


export default function Session(props) {
    const dispatch = useDispatch();
    const pastSessions = useSelector(state => state.liveTranscribe.pastSessions);
    const isTableEmpty = pastSessions.sessions.length === 0 && pastSessions.state === FETCH_SESSION_STATE.READY;

    const { setItems, viewItems, PaginationControl, paginationControlProps } = usePagination(20);
    useEffect(() => {
        setItems(pastSessions.sessions);
    }, [setItems, pastSessions.sessions])

    useEffect(() => {
        if (
            (pastSessions.state === FETCH_SESSION_STATE.INITIAL) ||
            (pastSessions.state === FETCH_SESSION_STATE.READY && (Date.now() - pastSessions.fetchTimestamp > INVALIDATION_TIME))
        ) {
            dispatch({ type: LIVE_TRANSCRIBE_FETCH_SESSIONS_SAGA });
        }
    }, [dispatch, pastSessions]);

    const formattedTimestamp = useCallback((timestamp) => {
        const date = new Date(timestamp);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    }, []);

    const refreshClicked = useCallback(() => {
        dispatch({ type: LIVE_TRANSCRIBE_FETCH_SESSIONS_SAGA });
    }, [dispatch]);

    const getStatusColumn = useCallback((status, creationTimestamp) => {
        switch (status) {
            case 'OPEN':
                return <progress className={classnames('progress', 'is-primary', styles.sessionProgressBar)} />;
            case 'COMPLETE':
                return <span>Ready</span>;
            default:
                return <>{status}</>;
        }
    }, []);

    const showOwner = pastSessions.sessions.some(sessionItem => !!sessionItem.owner);

    return (
        <>
            <ConsolePage levels={['Live Transcription', 'Sessions']} widget={<button className="button is-rounded is-small is-primary" onClick={refreshClicked}><FaSync className="mr-2" />Update</button>}>
                <section className="section is-main-section">
                    <div className="container">
                        <div className={classnames('b-table', { 'is-loading': pastSessions.state === FETCH_SESSION_STATE.FETCHING })}>
                            <div className="table-wrapper has-mobile-cards">
                                <table className={classnames('table', 'is-fullwidth', styles.sessionsTable)}>
                                    <thead>
                                        <tr>
                                            <th className={styles.iconCol}></th>
                                            <th className={styles.submittedCol}>Submitted</th>
                                            {showOwner && <th className={styles.ownerCol}>Owner</th>}
                                            <th className={styles.typeCol}>Type</th>
                                            <th className={styles.durationCol}>Length</th>
                                            <th className={styles.statusCol}>Status</th>
                                            <th className={styles.costCol}>Cost</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {isTableEmpty ? (
                                            <tr className="is-empty">
                                                <td colSpan="6" className="p-6 has-text-centered">
                                                    <p>
                                                        You haven’t started any live sessions yet. Head over to the <strong><Link to="/live-transcribe/setup" className="is-dotted-link">Setup</Link></strong> section to learn more about live transcription.
                                                    </p>
                                                </td>
                                            </tr>
                                        ) : (
                                            viewItems.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td><FaMicrophone /></td>

                                                        <td data-label="Submitted">
                                                            <span data-tooltip={formattedTimestamp(item.creationTimestamp)} className={classnames('has-tooltip-arrow', styles.tooltip)}>{timeAgo(item.creationTimestamp)}</span>
                                                        </td>

                                                        {showOwner && (
                                                            <td data-label="Owner" className={styles.ownerCol}>
                                                                <span>{item.owner}</span>
                                                            </td>
                                                        )}

                                                        <td className={styles.typeCol}>Live Stream</td>

                                                        <td data-label="Length">
                                                            {new Date(item.duration).toISOString().substr(11, 8)}
                                                        </td>

                                                        <td data-label="Status">
                                                            {getStatusColumn(item.status, item.creationTimestamp)}
                                                        </td>

                                                        <td className={classnames({ [styles.empty]: !item.cost })} data-label="Cost">
                                                            {item.cost ? `€${item.cost.toFixed(2)}` : '--'}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="mt-4">
                            <PaginationControl {...paginationControlProps} />
                        </div>
                    </div>
                </section>
            </ConsolePage>
        </>
    )
}