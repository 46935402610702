import ConsolePage from '../../../../../layouts/console';
import NotificationSettings from './components/notifications';

export default function Settings(props) {
    return (
        <ConsolePage levels={['Account', 'Settings']}>
            <section className="section is-main-section">
                <div className="container">
                    <NotificationSettings />
                </div>
            </section>
        </ConsolePage>
    )
}