import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import store from './store/store';
import { Provider } from 'react-redux'
import reportWebVitals from './reportWebVitals';
import axios from 'axios';

import { setLoggedOut } from './store/userSlice';


axios.interceptors.response.use((next) => {
  return Promise.resolve(next);
}, (err) => {
  if (err.response.status === 401) {  // Unauthorized 
    store.dispatch(setLoggedOut());
  }
  return Promise.reject(err);
});

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
