import { FaBell } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import axios from 'axios';

import { fetchUser } from '../../../../../../store/userSlice';


export default function Notifications() {
    const dispatch = useDispatch();
    const userNotifications = useSelector((state) => state.user.details?.settings?.notifications);
    const { register, handleSubmit, formState: { isSubmitting } } = useForm();

    const onSubmit = async (data) => {
        try {
            await axios.put('/api/account/notifications', data);
            await dispatch(fetchUser());
        }
        catch {
        }
    }

    return (
        <div className="tile is-ancestor">
            <div className="tile is-parent">
                <div className="card tile is-child">
                    <header className="card-header">
                        <p className="card-header-title">
                            <span className="icon"><FaBell /></span>
                            Notifications
                        </p>
                    </header>
                    <div className="card-content">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="field">
                                <div className="control">
                                    <label className="checkbox">
                                        <input type="checkbox" defaultChecked={userNotifications?.jobComplete} {...register('jobComplete')} />
                                        <span className="ml-3 is-unselectable">I would like to receive email notifications when jobs are completed.</span>
                                    </label>
                                </div>
                            </div>
                            <hr />
                            <div className="field">
                                <div className="field-label is-normal"></div>
                                <div className="field-body">
                                    <div className="field">
                                        <div className="control">
                                            <button disabled={isSubmitting} type="submit" className={classnames('button', 'is-primary', { 'is-loading': isSubmitting })}>
                                                Save Changes
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}