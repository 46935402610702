import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { LOGGED_IN } from '../store/userSlice';


const GuardedRoute = ({ component: Component, auth, ...rest }) => {
    const loginState = useSelector((state) => state.user.loginState);

    return (
        <Route {...rest} render={(props) => (
            loginState === LOGGED_IN
                ? <Component {...props} />
                : <Redirect to='/login' />
        )} />
    )
}

export default GuardedRoute;
