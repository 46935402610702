import { useState, useCallback, useEffect } from 'react';
import classnames from 'classnames';
import axios from 'axios';
import { useModalNoScroll } from '../../../../../../hooks/modal-noscroll';


function isValidYouTubeLink(inputUrl) {
    try {
        const url = new URL(inputUrl);

        // ensure that the url is a YouTube url
        if (url.hostname.toLowerCase() !== 'www.youtube.com') {
            return false;
        }

        // get the video param (v)
        const videoId = url.searchParams.get('v');
        if (!videoId) {
            return false;
        }

        return true;
    }
    catch {
        return false;
    }
}


export default function AddLinkItemModal({ show, close, addItem }) {

    const [isVerifying, setIsVerifying] = useState(false);
    const [inputUrl, setInputUrl] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const setModalActive = useModalNoScroll();
    useEffect(() => {
        setModalActive(show);

        setInputUrl('');
        setErrorMessage('')
    }, [setModalActive, show]);

    const addUrl = useCallback(async () => {
        const isValid = isValidYouTubeLink(inputUrl.trim());
        if (!isValid) {
            setErrorMessage('URL is not a valid YouTube video link.')
            return;
        }

        setIsVerifying(true);
        try {
            const response = await axios(`/api/transcribe/duration/link?link=${inputUrl.trim()}`);

            addItem({
                link: inputUrl.trim(),
                title: response.data.title,
                duration: response.data.duration,
                cost: response.data.cost
            });
            close();
        }
        catch {
            setErrorMessage('There was a problem validating the YouTube link.');
        }
        finally {
            setIsVerifying(false);
        }
    }, [inputUrl, addItem, close]);


    return (
        <div className={classnames('modal', 'modal-fx-slideTop', { 'is-active': show })}>
            <div className="modal-background"></div>
            <div className="modal-content">
                <header className="modal-card-head">
                    <p className="modal-card-title">Paste URL</p>
                    <button className="delete" aria-label="close" onClick={close}></button>
                </header>
                <section className="modal-card-body">
                    <div className="field">
                        <div className="control">
                            <input disabled={isVerifying} className="input" type="text" placeholder="YouTube URL" value={inputUrl} onChange={e => {
                                setErrorMessage('');
                                setInputUrl(e.target.value);
                            }} />
                        </div>
                        <p className="help fieldMessage">{errorMessage}</p>
                    </div>
                </section>
                <footer className="modal-card-foot is-justify-content-flex-end">
                    <button disabled={isVerifying} className="button is-primary" onClick={addUrl}>Add Link</button>
                </footer>
            </div>
        </div>
    )
}
