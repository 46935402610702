import styles from './progress-bar.module.css';

function ProgressBar(props) {
    return (
        <div className={styles.container} {...props}>
            {new Array(8).fill(undefined).map((_, i) => {
                return <div key={i} className={styles.bar} style={{
                    left: `${i * 29}px`,
                    animationDelay: `${i* 0.13}s`
                }}></div>
            })}
        </div>
    )
}

export default ProgressBar;