import classnames from 'classnames';
import { Link } from 'react-router-dom';

import BrandLogo from '../../components/brand-logo/brand-logo';

import styles from './dialog.module.scss';


function DialogLayout(props) {
    return (
        <>
            <nav className="navbar" role="navigation" aria-label="main navigation">
                <div className="container">
                    <div className="navbar-brand">
                        <Link to="/" className="navbar-item">
                            <BrandLogo />
                        </Link>
                    </div>
                </div>
            </nav>
            <section className={classnames('container', styles.container)}>
                <div className="columns is-centered">
                    <div className={classnames('column', 'is-8-tablet', 'is-5-widescreen', styles.formContainer)}>
                        <div className="columns">
                            <div className={classnames('column', styles.dialog)}>
                                {props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DialogLayout;
