import { useEffect, useState } from 'react';

import { PaginationControl } from '../components/pagination-control';


export function usePagination(itemsPerPage) {
    const [items, setItems] = useState([]);
    const [viewItems, setViewItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);

    useEffect(() => {
        const offset = currentPage * itemsPerPage;

        // if the offset is beyond number of items, change the currentPage
        if (offset >= items.length) {
            setCurrentPage(Math.max(0, Math.ceil(items.length / itemsPerPage) - 1));
        }
        else {
            setViewItems(items.slice(offset, offset + itemsPerPage));
        }
    }, [items, currentPage, itemsPerPage]);

    return {
        setItems,
        viewItems,
        PaginationControl,
        paginationControlProps: {
            itemCount: items.length,
            itemsPerPage,
            currentPage,
            setCurrentPage
        }
    }
}
