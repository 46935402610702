import { useRef, useEffect } from 'react';
import useResizeObserver from 'use-resize-observer/polyfilled';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Footer from '../../components/footer/footer';
import LegalBanner from '../../components/legal-banner/legal-banner';
import { LegalTextModal } from '../../components/legal-text-modal/legal-text-modal';
import LegalDocuments from '../../enums/LegalDocuments';
import { setCookieConsent, setAcceptedTerms, setCurrentLegalDocument } from '../../store/legalSlice';
import { trackPage } from '../../utils/analytics';

import styles from './common.module.scss';


export default function Common(props) {
    const cookieBannerRef = useRef();
    const cookieBannerSize = useResizeObserver({ ref: cookieBannerRef });

    const history = useHistory();

    const dispatch = useDispatch();
    const legal = useSelector((state) => state.legal);

    useEffect(() => {
        document.documentElement.style.setProperty('--legal-banner-height', `${cookieBannerSize.height}px`);
    }, [cookieBannerSize]);

    useEffect(() => {
        history.listen((location, action) => {
            trackPage(location.pathname);
        });
    }, [history]);

    return (
        <>
            <div className={styles.pageContainer}>
                <div className={styles.contentContainer}>
                    {props.children}
                </div>
                <Footer setCurrentLegalDocument={(document) => dispatch(setCurrentLegalDocument(document))} />
                <LegalBanner
                    ref={cookieBannerRef}
                    cookieConsent={legal.cookieConsent}
                    setCookieConsent={() => dispatch(setCookieConsent(1))}
                    acceptedTerms={legal.acceptedTerms}
                    setAcceptedTerms={() => dispatch(setAcceptedTerms(1))}
                    showPrivacyPolicy={() => dispatch(setCurrentLegalDocument(LegalDocuments.PrivacyPolicy))}
                    showTermsOfUse={() => dispatch(setCurrentLegalDocument(LegalDocuments.TermsOfUse))}
                    showCookiePolicy={() => dispatch(setCurrentLegalDocument(LegalDocuments.CookiePolicy))} />
            </div>

            <LegalTextModal
                show={legal.currentLegalDocument !== LegalDocuments.None}
                closeCallback={() => dispatch(setCurrentLegalDocument(LegalDocuments.None))}
                document={legal.currentLegalDocument} />
        </>
    )
}