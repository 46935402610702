import axios from 'axios';
import classnames from 'classnames';
import { useCallback, useEffect, useState } from 'react';

import { useModalNoScroll } from '../../../../../hooks/modal-noscroll';


export default function DeleteResultModal({ show, close, item, refreshJobs }) {
    const [isDeleting, setIsDeleting] = useState(false);

    const setModalActive = useModalNoScroll();
    useEffect(() => {
        setModalActive(show);
    }, [setModalActive, show]);

    const maybeClose = useCallback(() => {
        if (!isDeleting) {
            close();
        }
    }, [isDeleting])

    const onDelete = useCallback(async (item) => {
        if (isDeleting) {
            return;
        }

        const paramsSegment = item.userId ? `${item.id}/${item.userId}` : item.id;
        const requestUrl = `/api/transcribe/job/delete/${paramsSegment}`;

        setIsDeleting(true);

        try {
            const response = await axios.get(requestUrl);
            if (response.status !== 200) {
                throw new Error('Deleting transcript failed.');
            }

            refreshJobs();
            close();
        }
        catch {
        }
        finally {
            setIsDeleting(false);
        }
    }, [isDeleting, refreshJobs]);

    return (
        <div className={classnames('modal', 'modal-fx-slideTop', { 'is-active': show })}>
            <div className="modal-background"></div>
            <div className="modal-content">
                <header className="modal-card-head">
                    <p className="modal-card-title">Delete Transcript</p>
                    <button className="delete" aria-label="close" onClick={maybeClose}></button>
                </header>
                <section className="modal-card-body">
                    <p className="mb-4">Deleting the generated transcript for <span className="has-text-weight-medium">{item?.title}</span> is a permanent action that cannot be undone.</p>
                    <p className="mb-4">Select <span className="has-text-weight-medium has-text-danger">Delete</span> if you understand and want to proceed with the deletion, otherwise <span className="has-text-weight-medium has-text-primary">Cancel</span>.</p>
                </section>
                <footer className="modal-card-foot is-justify-content-flex-end">
                    <button className={classnames('button is-danger', { 'is-loading': isDeleting })} onClick={() => onDelete(item)}>Delete</button>
                    <button className="button is-primary" onClick={maybeClose}>Cancel</button>
                </footer>
            </div>
        </div>
    )
}
