import { useCallback, useEffect } from 'react';
import {
    FaClock, FaTasks, FaSync, FaMicrophone, FaArrowAltCircleRight,
    FaChartLine, FaBook, FaInfoCircle, FaCheckCircle, FaFileUpload
} from 'react-icons/fa';
import { IoMdCheckboxOutline } from "react-icons/io";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import ConsolePage from '../../../../layouts/console';
import { getStats, LoadingState } from '../../../../store/dashboardSlice';

import styles from './dashboard.module.scss';
import classNames from 'classnames';
import StatCard from '../../../../components/stat-card/stat-card';


const INVALIDATION_TIME = 60 * 5 * 1000;

function secondsToMinutes(seconds) {
    return Math.round(seconds / 60 * 10) / 10;
}


export default function Dashboard(props) {
    const dispatch = useDispatch();
    const dashboard = useSelector((state) => state.dashboard);
    const pricing = useSelector((state) => state.user.details.pricing);
    const { fetchTimestamp, loadingState, data } = dashboard;

    const isLoading = loadingState !== LoadingState.Loaded;

    const refreshClicked = useCallback(() => {
        dispatch(getStats());
    }, [dispatch]);

    useEffect(() => {
        if (
            (loadingState === LoadingState.Pending) ||
            (loadingState === LoadingState.Loaded && (Date.now() - fetchTimestamp > INVALIDATION_TIME))
        ) {
            dispatch(getStats());
        }
    }, [dispatch, fetchTimestamp, loadingState]);

    return (
        <ConsolePage levels={['General', 'Dashboard']} widget={<button className="button is-rounded is-small is-primary" onClick={refreshClicked}><FaSync className="mr-2" />Update</button>}>
            <section className="section is-main-section">
                <div className="container">
                    <div className="columns is-desktop">

                        <div className="column is-full is-two-thirds-desktop">
                            <div className="is-flex is-align-items-center mb-4">
                                <FaBook className="mr-4 has-text-primary" size="1rem" />
                                <h5 className="title is-5 has-text-grey-darker">Guidance</h5>
                            </div>

                            <div className="tile is-ancestor mb-6">
                                <div className="tile is-parent">
                                    <div className="card tile is-child">
                                        <header className="card-header">
                                            <p className="card-header-title">
                                                <span className="icon">
                                                    <FaCheckCircle />
                                                </span>Recording Tips
                                            </p>
                                        </header>
                                        <div className="card-content">
                                            <div className="is-flex is-flex-direction-column">
                                                <div className={classNames('is-block mb-1 has-text-grey-dark')}>
                                                    <table>
                                                        <tbody>
                                                            {[
                                                                'Use a high-quality microphone and position it close to the speaker’s mouth to avoid distortion.',
                                                                'Record in a quiet environment with minimal background noise.',
                                                                'Avoid overlapping speech, where two or more people speak at the same time.',
                                                                'If you’re recording remotely, use a stable internet connection to avoid audio dropouts.'
                                                            ].map((text, index) => <tr key={index}>
                                                                <td><IoMdCheckboxOutline className={classNames(styles.checkmark)} /></td>
                                                                <td>{text}</td>
                                                            </tr>)}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="is-flex is-align-items-center mb-4">
                                <FaChartLine className="mr-4 has-text-primary" size="1rem" />
                                <h5 className="title is-5 has-text-grey-darker">Stats</h5>
                            </div>

                            <div className="tile is-ancestor">
                                <StatCard
                                    isLoading={isLoading}
                                    icon={<FaTasks />}
                                    title="Jobs"
                                    value={data?.transcription?.totalJobs}
                                    valueColor="#23d160"
                                    text="file transcription jobs completed so far."
                                />

                                <StatCard
                                    isLoading={isLoading}
                                    icon={<FaClock />}
                                    title="Time"
                                    value={secondsToMinutes(data?.transcription?.totalDurationSeconds)}
                                    valueColor="#23d160"
                                    text="minutes of file audio data processed so far."
                                />
                            </div>

                            <div className="tile is-ancestor">
                                <StatCard
                                    isLoading={isLoading}
                                    icon={<FaMicrophone />}
                                    title="Live Sessions"
                                    value={data?.liveTranscription?.totalSessions}
                                    valueColor="#ffdd57"
                                    text="live sessions so far."
                                />

                                <StatCard
                                    isLoading={isLoading}
                                    icon={<FaClock />}
                                    title="Time"
                                    value={secondsToMinutes(data?.liveTranscription?.totalDurationSeconds)}
                                    valueColor="#ffdd57"
                                    text="minutes of live audio data processed so far."
                                />
                            </div>
                        </div>

                        <div className="column">
                            <div className="is-flex is-align-items-baseline mb-4">
                                <FaInfoCircle className="mr-3 has-text-primary" size="0.9rem" />
                                <h5 className="title is-5 has-text-grey-darker">Services</h5>
                            </div>
                            <div className={classNames('card', styles.servicesCard)}>
                                <div className="card-content">
                                    <div className="content">
                                        <h4 className="subtitle is-4 has-text-weight-light"><FaFileUpload className="mr-1" size="1rem" /> File Transcription</h4>
                                        <p className="mb-5">
                                            Upload your audio or video files and submit them for transcription.
                                        </p>
                                        <ul className={classNames('is-block mb-6', styles.featureList)}>
                                            <li>Transcribe audio and video files.</li>
                                            <li>Multiple transcript formats to choose from.</li>
                                            <li>Get transcripts in minutes.</li>
                                        </ul>
                                        <span className="is-block"><strong>€{pricing.transcription.toFixed(2)}</strong> / <span style={{ fontSize: '0.85rem' }}>minute</span></span>
                                        <span className="is-block is-size-7 has-text-grey mt-1 mb-5">Billed at the start of each period.</span>
                                        <p><Link to="/transcribe/create">Get Started <FaArrowAltCircleRight fontSize="0.9rem" /></Link></p>

                                        <hr className="mx-2 has-background-grey-lighter" />
                                        <h4 className="subtitle is-4 has-text-weight-light"><FaMicrophone className="mr-1" size="1rem" /> Live Transcription</h4>
                                        <p className="mb-5">
                                            Connect your microphone and start talking to get real-time transcription. Alternatively, hook up an external source to your line-in input, for example your TV, to get real-time subtitling.
                                        </p>
                                        <ul className={classNames('is-block mb-6', styles.featureList)}>
                                            <li>Transcribe audio in real-time.</li>
                                            <li>Customise the look of the output.</li>
                                            <li>Requires a stable Internet connection.</li>
                                        </ul>
                                        <span className="is-block"><strong>€{pricing.liveTranscription.toFixed(2)}</strong> / <span style={{ fontSize: '0.85rem' }}>15 seconds</span></span>
                                        <span className="is-block is-size-7 has-text-grey mt-1 mb-5">Billed at the start of each period.</span>
                                        <span><Link to="/live-transcribe/setup">Get Started <FaArrowAltCircleRight fontSize="0.9rem" /></Link></span>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </ConsolePage>
    )
}