import ConsolePage from '../../../../../layouts/console';
import EmailInvite from './components/email-invite';

export default function Settings(props) {
    return (
        <ConsolePage levels={['Admin', 'Invite']}>
            <section className="section is-main-section">
                <div className="container">
                    <EmailInvite />
                </div>
            </section>
        </ConsolePage>
    )
}