import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';


export const LoadingState = {
  Pending: 'PENDING',
  Loaded: 'LOADED',
  Failed: 'FAILED'
}


export const getBilling = createAsyncThunk(
  'billing/getBilling',
  async () => {
    try {
      const response = await axios.get('/api/billing/');
      return {
            loadingState: LoadingState.Loaded,
          ...response.data
      }
    }
    catch {
      return {
        loadingState: LoadingState.Failed,
      }
    }
  }
);

export const billingSlice = createSlice({
  name: 'billing',
  initialState: {
    loadingState: LoadingState.Pending,
    balance: 0,
    availableBalance: 0,
    transactions: []
  },
  extraReducers: {
    [getBilling.fulfilled]: (state, action) => {
      return action.payload;
    }
  }
})

export default billingSlice.reducer;
