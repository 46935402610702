import Joi from 'joi';

import { email, password } from './common';


const schema = Joi.object({
    email,
    password
})

export default schema;
