import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import classNames from 'classnames';


export function PaginationControl({ currentPage, itemsPerPage, itemCount, setCurrentPage }) {
    const totalPages = Math.ceil(itemCount / itemsPerPage);
    return (
        <>
            {(totalPages > 1) && (
                <nav className="pagination is-small" role="navigation" aria-label="pagination">
                    {(currentPage > 0) && <a className="pagination-previous" onClick={() => setCurrentPage(currentPage - 1)}><FaAngleLeft /></a>}
                    {(currentPage < totalPages - 1) && <a className="pagination-next" onClick={() => setCurrentPage(currentPage + 1)}><FaAngleRight /></a>}

                    <ul className="pagination-list">
                        <>
                            {[...Array(totalPages).keys()].map(p => {
                                const distance = Math.abs(p - currentPage);
                                if (distance <= 2 || p === 0 || p === totalPages - 1) {
                                    return (
                                        <li key={p}>
                                            <a
                                                className={classNames('pagination-link', { 'is-current': currentPage === p })}
                                                onClick={() => setCurrentPage(p)}
                                                aria-label={`Goto page ${p + 1}`}>
                                                {p + 1}
                                            </a>
                                        </li>
                                    )
                                }
                                else if (distance === 3) {
                                    return (
                                        <li key={p}>
                                            <span className="pagination-ellipsis">&hellip;</span>
                                        </li>
                                    )
                                }

                                return null;
                            })}
                        </>
                    </ul>
                </nav>
            )}
        </>
    )
}
