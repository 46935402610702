import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    queueUploadItem, removeItem, clearItems,
    ITEM_TYPE, ITEM_STATE,
    TRANSCRIBE_ADD_LINK_ITEM_SAGA, TRANSCRIBE_ADD_UPLOAD_ITEM_SAGA, TRANSCRIBE_FETCH_JOBS_SAGA
} from '../../../../../store/transcribeSlice';

import ConsolePage from '../../../../../layouts/console';
import AddLinkItem from './components/add-link-item';
import ItemsTable from './components/items-table';
import InsufficientBalanceModal from './components/insufficient-balance-modal';

import AddUploadtem from './components/add-upload-item';
import axios from 'axios';
import classnames from 'classnames';
import Notice from '../../../../../components/notice';


export default function NewPage(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [insufficientBalanceModal, setInsufficientBalanceModal] = useState(null);
    const queue = useSelector((state) => state.transcribe.createQueue);
    const isReady = queue.every(item => item.state === ITEM_STATE.READY) && (queue.length > 0);

    const onRemove = useCallback(item => {
        if (item.type === ITEM_TYPE.UPLOAD && item.state === ITEM_STATE.UPLOADING) {
            item.cancelToken.cancel();
        }
        else {
            dispatch(removeItem({ id: item.id }));
        }
    }, [dispatch])

    const onAddUrl = useCallback((item) => {
        dispatch({
            type: TRANSCRIBE_ADD_LINK_ITEM_SAGA,
            payload: {
                type: ITEM_TYPE.LINK,
                ...item
            }
        })
    }, [dispatch])

    const onAddUpload = useCallback((item) => {
        // queue item
        dispatch(queueUploadItem({
            type: ITEM_TYPE.UPLOAD,
            title: item.file.name,
            file: item.file
        }));

        // Queue the upload
        dispatch({
            type: TRANSCRIBE_ADD_UPLOAD_ITEM_SAGA,
            file: item.file
        })
    }, [dispatch])

    const onSubmit = async () => {
        const requestItems = [];

        for (let item of queue) {
            if (item.state !== ITEM_STATE.READY) {  // shouldn't be possible, bail out
                return;
            }

            if (item.type === ITEM_TYPE.UPLOAD) {
                requestItems.push({
                    type: ITEM_TYPE.UPLOAD,
                    fileMetadata: item.fileMetadata,
                    uploadId: item.uploadId
                });
            }
            else if (item.type === ITEM_TYPE.LINK) {
                requestItems.push({
                    type: ITEM_TYPE.LINK,
                    title: item.title,
                    link: item.link
                });
            }
        }

        setIsSubmitting(true);
        try {
            await axios.post('/api/transcribe/jobs', requestItems);
            dispatch(clearItems());  // clear items from store

            dispatch({ type: TRANSCRIBE_FETCH_JOBS_SAGA });
            history.push('/transcribe/jobs')
        }
        catch (e) {
            if (e.response.status === 402) {
                setInsufficientBalanceModal({ ...e.response.data });
            }
            setIsSubmitting(false);
        }
    }

    return (
        <>
            <InsufficientBalanceModal
                show={!!insufficientBalanceModal}
                close={() => setInsufficientBalanceModal(null)}
                billing={() => history.push('/billing/manage')}
                availableBalance={insufficientBalanceModal?.availableBalance}
                cost={insufficientBalanceModal?.cost}
            />
            <ConsolePage levels={['File Transcription', 'Create']}>
                <section className="section is-main-section">
                    <div className="container">
                        <div className="tile is-ancestor">
                            <AddUploadtem addUploadItem={onAddUpload} />
                            <div className="divider is-vertical">OR</div>
                            <AddLinkItem addLinkItem={onAddUrl} />
                        </div>
                    </div>
                </section>

                <section className="section pb-6">
                    {
                        <div className="container">
                            <ItemsTable queue={queue} onRemove={onRemove} />

                            <div className="columns mt-4">
                                <div className="column is-three-quarters">
                                    <Notice />
                                </div>
                                <div className="column is-one-quarter">
                                    <button disabled={!isReady || isSubmitting}
                                        className={classnames('button is-primary is-fullwidth', { 'is-loading': isSubmitting })}
                                        onClick={onSubmit}>Transcribe</button>
                                </div>
                            </div>
                        </div>
                    }
                </section>
            </ConsolePage>
        </>
    )
}