import { useState, useEffect } from 'react';
import classnames from 'classnames';
import { FaEuroSign, FaCreditCard, FaReceipt } from 'react-icons/fa';
import InputAmountPage from './input-amount';
import PaymentDetailsPage from './payment-details';
import SummaryPage from './summary';
import { useModalNoScroll } from '../../../../../../hooks/modal-noscroll';


export default function BillingModal({ show, updateBalanceCb, close }) {
    const [page, setPage] = useState(0);
    const [amount, setAmount] = useState(null);
    const [isProcessingPayment, setIsProcessingPayment] = useState(false);

    useEffect(() => {
        // reset all values
        if (show) {
            setPage(0);
            setAmount(5);
            setIsProcessingPayment(false);
        }
    }, [show, setPage, setAmount, setIsProcessingPayment]);

    const setModalActive = useModalNoScroll();
    useEffect(() => {
        setModalActive(show);
    }, [setModalActive, show]);

    useEffect(() => {
        if (page === 2) {  // success page, update the balance
            // HACK: add a delay to give time for the backend to finish update the db
            setTimeout(() => updateBalanceCb(), 5000);
        }
    }, [page, updateBalanceCb]);

    return (
        <div className={classnames('modal', 'modal-fx-slideTop', { 'is-active': show })}>
            <div className="modal-background"></div>
            <div className="modal-content">
                <header className="modal-card-head">
                    <p className="modal-card-title">Top-up</p>
                    <button className="delete" aria-label="close" onClick={() => {
                        if (!isProcessingPayment) {
                            close()
                        }
                    }}></button>
                </header>
                <section className="modal-card-body">
                    <ul className="steps is-narrow is-medium is-centered has-content-centered">
                        <li className={classnames('steps-segment', { 'is-active': page === 0 })}>
                            <span className="steps-marker">
                                <FaEuroSign />
                            </span>
                            <div className="steps-content">
                                <p className="heading">Amount</p>
                            </div>
                        </li>
                        <li className={classnames('steps-segment', { 'is-active': page === 1 })}>
                            <span className="steps-marker">
                                <FaCreditCard />
                            </span>
                            <div className="steps-content">
                                <p className="heading">Details</p>
                            </div>
                        </li>
                        <li className={classnames('steps-segment', 'has-gaps', { 'is-active': page === 2 })}>
                            <span className="steps-marker">
                                <FaReceipt />
                            </span>
                            <div className="steps-content">
                                <p className="heading">Summary</p>
                            </div>
                        </li>
                    </ul>

                    <hr />

                    {page === 0 && <InputAmountPage setAmount={setAmount} />}
                    {page === 1 && <PaymentDetailsPage
                        amount={amount}
                        isProcessingPayment={isProcessingPayment}
                        setIsProcessingPayment={setIsProcessingPayment}
                        successCb={() => setPage(2)}
                    />}
                    {page === 2 && <SummaryPage amount={amount} />}
                </section>
                <footer className="modal-card-foot is-justify-content-flex-end">
                    {page === 0 && <button className="button is-primary" disabled={!amount} onClick={() => setPage(1)}>Checkout</button>}
                    {page === 1 && (
                        <>
                            <button className="button" disabled={isProcessingPayment} onClick={() => setPage(0)}>Back</button>
                            <button type="submit" disabled={isProcessingPayment} className={classnames('button', 'is-primary', { 'is-loading': isProcessingPayment })} form="stripe-payment-form">Submit</button>
                        </>
                    )}
                    {page === 2 && <button className="button is-primary" onClick={() => close()}>Close</button>}
                </footer>
            </div>
        </div >
    )
}
