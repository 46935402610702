import { FaDesktop, FaTasks, FaCog, FaPen, FaUser, FaUserPlus, FaReceipt, FaToolbox } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import { Role } from '../../store/userSlice';


export function Aside() {
    const isAdmin = useSelector((state) => state.user?.details?.roles.findIndex(r => r === Role.Admin) !== -1);
    const { pathname } = useLocation();

    return (
        <aside className='aside' style={{ overflow: 'auto' }}>
            <div className="aside-tools">
                <div className="aside-tools-label">
                </div>
            </div>

            <div className="menu is-menu-main mb-4">
                <p className="menu-label">General</p>
                <ul className="menu-list">
                    <li>
                        <Link to="/" className={classnames('has-icon', { 'is-active': pathname === '/' })}>
                            <span className="icon"><FaDesktop /></span>
                            <span className="menu-item-label">Dashboard</span>
                        </Link>
                    </li>
                </ul>
                <p className="menu-label mt-5">File Transcription</p>
                <ul className="menu-list">
                    <li>
                        <Link to="/transcribe/create" className={classnames('has-icon', { 'is-active': pathname === '/transcribe/create' })}>
                            <span className="icon"><FaPen /></span>
                            <span className="menu-item-label">Create</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/transcribe/jobs" className={classnames('has-icon', { 'is-active': pathname === '/transcribe/jobs' })}>
                            <span className="icon"><FaTasks /></span>
                            <span className="menu-item-label">Jobs</span>
                        </Link>
                    </li>
                </ul>
                <p className="menu-label mt-5">Live Transcription</p>
                <ul className="menu-list">
                    <li>
                        <Link to="/live-transcribe/setup" className={classnames('has-icon', { 'is-active': pathname === '/live-transcribe/setup' })}>
                            <span className="icon"><FaToolbox /></span>
                            <span className="menu-item-label">Setup</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/live-transcribe/sessions" className={classnames('has-icon', { 'is-active': pathname === '/live-transcribe/sessions' })}>
                            <span className="icon"><FaTasks /></span>
                            <span className="menu-item-label">Sessions</span>
                        </Link>
                    </li>
                </ul>
                {isAdmin && (
                    <>
                        <p className="menu-label mt-5">Admin</p>
                        <ul className="menu-list">
                            <li>
                                <Link to="/admin/invite" className={classnames('has-icon', { 'is-active': pathname === '/admin/invite' })}>
                                    <span className="icon"><FaUserPlus /></span>
                                    <span className="menu-item-label">Invite</span>
                                </Link>
                            </li>
                        </ul>
                    </>
                )}
                <p className="menu-label mt-5">Account</p>
                <ul className="menu-list">
                    <li>
                        <Link to="/account/profile" className={classnames('has-icon', { 'is-active': pathname === '/account/profile' })}>
                            <span className="icon"><FaUser /></span>
                            <span className="menu-item-label">Profile</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/account/settings" className={classnames('has-icon', { 'is-active': pathname === '/account/settings' })}>
                            <span className="icon"><FaCog /></span>
                            <span className="menu-item-label">Settings</span>
                        </Link>
                    </li>
                </ul>

                <p className="menu-label mt-5">Billing</p>
                <ul className="menu-list">
                    <li>
                        <Link to="/billing/manage" className={classnames('has-icon', { 'is-active': pathname === '/billing/manage' })}>
                            <span className="icon"><FaReceipt /></span>
                            <span className="menu-item-label">Manage</span>
                        </Link>
                    </li>
                </ul>
            </div>
        </aside>
    )
}

export default Aside