import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import LegalDocuments from '../enums/LegalDocuments';


export const legalSlice = createSlice({
    name: 'legal',
    initialState: {
        cookieConsent: Number(Cookies.get('cookie_consent')) || 0,
        acceptedTerms: Number(Cookies.get('accepted_terms')) || 0,
        currentLegalDocument: LegalDocuments.None
    },
    reducers: {
        setCookieConsent: (state) => {
            Cookies.set('cookie_consent', 1, { expires: 365, domain: 'bilmalti.com' });
            state.cookieConsent = 1;
        },

        setAcceptedTerms: (state) => {
            Cookies.set('accepted_terms', 1, { expires: 365, domain: 'bilmalti.com' });
            state.acceptedTerms = 1;
        },

        setCurrentLegalDocument: (state, action) => {
            state.currentLegalDocument = action.payload;
        }
    }
})

export const { setCookieConsent, setAcceptedTerms, setCurrentLegalDocument } = legalSlice.actions;

export default legalSlice.reducer;
