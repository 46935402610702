import ProgressBar from '../../components/progress-bar';

import style from './loading.module.css';


function LoadingPage() {
    return (
        <div className={style.container}>
            <ProgressBar/>
        </div>
    )
}

export default LoadingPage;