import classnames from 'classnames';
import styles from './brand-logo.module.scss';


export default function BrandLogo({ inverted, large }) {
    return (
        <div className={classnames('is-flex', 'is-align-items-center', {
            [styles.inverted]: inverted,
            [styles.large]: large
        })}>
            <div className={styles.brandLogo} />
            <div className={styles.betaLabel}>BETA</div>
        </div >
    )
}