import { useCallback } from 'react';
import { FaFileUpload } from 'react-icons/fa';
import { useDropzone } from 'react-dropzone';

import styles from './add-upload-item.module.scss';


export default function AddUploadtem(props) {
    const { addUploadItem } = props;

    const onDrop = useCallback(acceptedFiles => {
        acceptedFiles.forEach(element => {
            addUploadItem({
                file: element
            });
        });
    }, [addUploadItem]);

    const { isDragActive, getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'audio/*,video/*'
    });

    const dropzoneStyle = {
        cursor: 'pointer',
        width: '100%',
        padding: '1rem',
        borderWidth: '2px',
        borderRadius: '2px',
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        outline: 'none',
        transition: 'border 0.25s ease-in-out'
    }

    const activeDropzone = {
        borderColor: '#f03a5f'
    }

    const style = {
        ...dropzoneStyle,
        ...(isDragActive ? activeDropzone : {})
    }

    return (
        <div className="tile is-parent">
            <div className="card tile is-child">
                <header className="card-header">
                    <p className="card-header-title">
                        <span className="icon"><FaFileUpload /></span>
                        File Upload
                    </p>
                </header>
                <div className="card-content">
                    <div className={styles.outerContentArea}>
                        <div {...getRootProps({ style })}>
                            <input {...getInputProps()} />

                            <p className="is-size-5">Drag and drop media files here, or click this area to select.</p>
                            <button className="button is-danger mt-3">Upload Files</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}