import Joi from 'joi';

import { firstName, lastName, email, password } from './common';


const schema = Joi.object({
    firstName,
    lastName,
    email,
    password,
    repeatPassword: Joi.equal(Joi.ref('password')),
});

export default schema;
