import { useState, useCallback, useEffect } from 'react';
import classnames from 'classnames';
import axios from 'axios';
import contentDisposition from 'content-disposition';
import { FaClosedCaptioning, FaFileAlt, FaFileWord } from 'react-icons/fa';

import fileDownload from 'js-file-download';
import { useModalNoScroll } from '../../../../../hooks/modal-noscroll';

import styles from './download-result-modal.module.scss';


export default function DownloadResultModal({ show, close, item, isUserAdmin }) {
    const [isLoading, setIsLoading] = useState(false);
    const [shouldTruncate, setShouldTruncate] = useState(true);

    const setModalActive = useModalNoScroll();
    useEffect(() => {
        setModalActive(show);
    }, [setModalActive, show]);

    const onDownload = useCallback(async (item, format) => {
        if (isLoading) {
            return;
        }

        const params = {
            format: format,
            truncate: shouldTruncate
        };

        setIsLoading(true);
        const paramsSegment = item.userId ? `${item.id}/${item.userId}` : item.id;
        const queryParams = new URLSearchParams(params).toString();
        const requestUrl = `/api/transcribe/job/${paramsSegment}/?${queryParams}`;
        try {
            const response = await axios.get(
                requestUrl,
                { responseType: 'blob' }
            );
            const parsedContentDisposition = contentDisposition.parse(response.headers['content-disposition']);
            const filename = parsedContentDisposition.parameters.filename;
            fileDownload(response.data, filename);
        }
        catch {
        }
        finally {
            setIsLoading(false);
        }
    }, [isLoading, shouldTruncate]);

    return (
        <div className={classnames('modal', 'modal-fx-slideTop', { 'is-active': show })}>
            <div className="modal-background"></div>
            <div className="modal-content">
                <header className="modal-card-head">
                    <p className="modal-card-title">Download Transcript</p>
                    <button className="delete" aria-label="close" onClick={close}></button>
                </header>
                <section className="modal-card-body">
                    <h6 className="title is-6 mb-4">{item?.title}</h6>
                    {isUserAdmin && (
                        <div className="message mt-4">
                            <div className="message-body">
                                <p className="is-size-6 has-text-weight-semibold mb-2">Admin</p>
                                <label className="checkbox no-user-select">
                                    <input className="mr-3" type="checkbox" disabled={isLoading} checked={shouldTruncate} onChange={(e) => setShouldTruncate(e.target.checked)} />
                                    Truncate subtitle length.
                                </label>
                            </div>
                        </div>
                    )}
                    <p className="mb-4">Choose your preferred transcript format.</p>
                    <div className={classnames(styles.itemGroup, { [styles.disabled]: isLoading })}>
                        <div onClick={() => onDownload(item, 'srt')} className={classnames('p-3', styles.itemFormat)}>
                            <FaClosedCaptioning className="mr-3" />SubRip Subtitles
                        </div>
                        <div onClick={() => onDownload(item, 'txt')} className={classnames('p-3', styles.itemFormat)}>
                            <FaFileAlt className="mr-3" />Text Document
                        </div>
                        <div onClick={() => onDownload(item, 'txt-with-timings')} className={classnames('p-3', styles.itemFormat)}>
                            <FaFileAlt className="mr-3" />Text Document with Timestamps
                        </div>
                        <div onClick={() => onDownload(item, 'docx')} className={classnames('p-3', styles.itemFormat)}>
                            <FaFileWord className="mr-3" />Word Document
                        </div>
                        <div onClick={() => onDownload(item, 'docx-with-timings')} className={classnames('p-3', styles.itemFormat)}>
                            <FaFileWord className="mr-3" />Word Document with Timestamps
                        </div>
                    </div>
                </section>
                <footer className="modal-card-foot is-justify-content-flex-end">
                    <button className="button is-primary" onClick={() => close()}>Close</button>
                </footer>
            </div>
        </div>
    )
}
