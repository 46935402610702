import { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { MdMailOutline } from 'react-icons/md';


import forgotPasswordSchema from '../../validation/forgot-password';

import SplitLayout from '../../layouts/split/split';


function prettyMessage(msg) {
    if (msg) {
        if (msg.startsWith('"email"')) {
            msg = msg.replace('"email"', 'Email');
        }

        // Capitalize first letter
        return msg.charAt(0).toUpperCase() + msg.slice(1);
    }
    return msg;
}


export default function ForgotPassword() {
    const [hasSentRequest, setHasSentRequest] = useState(false);
    const [email, setEmail] = useState();

    const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm({
        resolver: joiResolver(forgotPasswordSchema)
    });
    const onSubmit = async (data) => {
        try {
            await axios.post('/api/forgot-password', {
                username: data.email
            });
            setEmail(data.email);
            setHasSentRequest(true);
        }
        catch (e) {

        }
    };

    const isDisabled = isSubmitting;

    return (
        <SplitLayout>
            <h3 className="title is-3">Reset Password</h3>
            <p className="description">Please enter the email address you used when creating your account.</p>
            <br />

            {hasSentRequest ? (
                <>
                    <article className="message is-info">
                        <div className="message-body">
                            <p className="has-text-weight-medium">Please check your Inbox.</p>
                            <p className="mt-2">An email is on the way to {email} with instructions for resetting your password.</p>
                        </div>
                    </article>

                    <p>To reset your password, follow the instructions in the email we've just sent you from noreply@bilmalti.com. </p>
                </>) : (

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="field">
                        <label className="label" htmlFor="email">Email</label>
                        <div className="control has-icons-left">
                            <input disabled={isDisabled} className="input" name="email" type="text" {...register('email')} />
                            <span className="icon is-small is-left">
                                <MdMailOutline />
                            </span>
                        </div>
                        <p className="help is-danger fieldMessage">{prettyMessage(errors.email?.message)}</p>
                    </div>

                    <button disabled={isDisabled} type="submit" className="button is-block is-primary is-fullwidth is-medium">Send Reset Email</button>
                </form>
            )}

            <div className="mt-6">
                <Link to="/login">← Go back to sign-in</Link>
            </div>
        </SplitLayout>
    );
}