import { useEffect } from 'react';
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import './scss/main.scss';
import 'bulma-modal-fx/dist/css/modal-fx.css'
import 'placeholder-loading/dist/css/placeholder-loading.css';

import { enableAnalytics, disableAnalytics } from './utils/analytics';

import GuardedRoute from './routes/guarded-route';
import LoggedOutRoute from './routes/logged-out-route';

import CommonLayout from './layouts/common/common';

import LoadingPage from './pages/loading/loading'
import Login from './pages/login/login';
import Register from './pages/register/register';
import ForgotPassword from './pages/forgot-password/forgot-password';
import ResetPassword from './pages/reset-password/reset-password';
import Dashboard from './pages/dashboard/dashboard';
import { FETCH_USER_SAGA, PENDING } from './store/userSlice';

const stripePromise = loadStripe(
  // 'pk_test_51JPuOzJQAeAdhIjIKfnxWY8l0kJbxQVAh5RV5whBjm6og9Ctzi6RsMHroEYmjIgeW7Qonrt7C03brNI2NyYJFzA000yY8NZvtD'  // for test
  'pk_live_51JPuOzJQAeAdhIjIqDNK7mbtL7lXPiWYBQe2y73otJ0kC5dyaQU1uwmiZM9gePlWYoHBrg1Z6vE0nMHuqSVcyc6a00PfHx26RU',  // for prod
);


function App() {
  const dispatch = useDispatch();
  const loginState = useSelector((state) => state.user.loginState);
  const cookieConsent = useSelector((state) => state.legal.cookieConsent);

  useEffect(() => {
    // Check whether cookie consent is set
    if (cookieConsent) {
      enableAnalytics();
    }
    else {
      disableAnalytics();
    }
  }, [cookieConsent]);

  useEffect(() => {
    dispatch({ type: FETCH_USER_SAGA });
  }, [dispatch]);

  const content = (
    <Elements stripe={stripePromise}>
      <BrowserRouter>
        <CommonLayout>

          <Switch>
            <LoggedOutRoute path="/login" component={Login} />
            <LoggedOutRoute path="/register/:inviteCode?" component={Register} />
            <LoggedOutRoute path="/password/forgot" component={ForgotPassword} />
            <LoggedOutRoute path="/password/reset/:id/:token" component={ResetPassword} />

            <GuardedRoute path="/" component={Dashboard} />

            <Route>
              <Redirect to="/" />
            </Route>
          </Switch>
        </CommonLayout>
      </BrowserRouter>
    </Elements>
  )

  return (
    loginState === PENDING ? <LoadingPage /> : content
  )
}

export default App;
