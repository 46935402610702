import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { USER_LOGOUT_SAGA } from '../../store/userSlice';
import { FaSignOutAlt, FaBars } from 'react-icons/fa';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import GuardedRoute from '../../routes/guarded-route';
import AdminRoute from '../../routes/admin-route';

import ConsoleHomePage from './section/dashboard/dashboard';
import LiveTranscribeSetupPage from './section/live-transcribe/setup/setup';
import LiveTranscribeSessionsPage from './section/live-transcribe/sessions/sessions';
import TranscribeCreatePage from './section/transcribe/create/create';
import TranscribeJobsPage from './section/transcribe/jobs/jobs';
import InvitePage from './section/admin/invite/invite';
import AccountProfilePage from './section/account/profile/profile';
import AccountSettingsPage from './section/account/settings/settings';
import BillingManagementPage from './section/billing/manage/manage';

import BrandLogo from '../../components/brand-logo/brand-logo';
import Aside from './aside.js';
import ScrollToTop from '../../components/scroll-to-top';


export default function Dashboard() {
    const dispatch = useDispatch();

    const location = useLocation();
    useEffect(() => {
        document.documentElement.classList.remove('has-aside-mobile-expanded');
    }, [location]);

    useEffect(() => {
        document.documentElement.classList.add('has-aside-expanded');

        return () => {
            document.documentElement.classList.remove('has-aside-expanded');
            document.documentElement.classList.remove('has-aside-mobile-expanded');
        }
    }, []);

    const mobileSidemenuIconClick = () => {
        document.documentElement.classList.toggle('has-aside-mobile-expanded');
    }

    const onLogout = () => {
        dispatch({
            type: USER_LOGOUT_SAGA
        })
    };

    const onBurgerClicked = (e) => {
        e.target.classList.toggle('is-active');
        const el = document.querySelector('.navbar-menu');
        el.classList.toggle('is-active');
    }

    return (
        <>
            <nav className="navbar is-fixed-top dash-navbar" role="navigation" aria-label="main navigation">
                <div className="container">
                    <div className="navbar-brand">
                        <a className="navbar-item is-hidden-desktop" onClick={mobileSidemenuIconClick}>
                            <FaBars />
                        </a>
                        <div className="navbar-item">
                            <BrandLogo />
                        </div>

                        <a role="button" className="navbar-burger" aria-label="menu" aria-expanded="false" onClick={onBurgerClicked}>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                        </a>
                    </div>

                    <div className="navbar-menu" id="nav-links">
                        <div className="navbar-end">
                            <a title="Log out" className="navbar-item" onClick={onLogout}>
                                <span className="icon"><FaSignOutAlt /></span>
                            </a>
                        </div>
                    </div>
                </div>
            </nav>

            <Aside />

            <ScrollToTop />
            <Switch>
                <GuardedRoute exact path="/" component={ConsoleHomePage} />
                <GuardedRoute path="/live-transcribe/setup" component={LiveTranscribeSetupPage} />
                <GuardedRoute path="/live-transcribe/sessions" component={LiveTranscribeSessionsPage} />
                <GuardedRoute path="/transcribe/create" component={TranscribeCreatePage} />
                <GuardedRoute path="/transcribe/jobs" component={TranscribeJobsPage} />
                <AdminRoute path="/admin/invite" component={InvitePage} />
                <GuardedRoute path="/account/profile" component={AccountProfilePage} />
                <GuardedRoute path="/account/settings" component={AccountSettingsPage} />
                <GuardedRoute path="/billing/manage" component={BillingManagementPage} />
                <Route>
                    <Redirect to="/" />
                </Route>
            </Switch>
        </>
    );
}