import { useState, useCallback } from 'react';
import { FaYoutube } from 'react-icons/fa';

import AddLinkItemModal from './add-link-item-modal';

import styles from './add-link-item.module.scss';


export default function AddLinkItem(props) {
    const [showModal, setShowModal] = useState(false);
    const { addLinkItem } = props;

    const addItem = useCallback((item) => {
        addLinkItem(item);
    }, [addLinkItem]);

    return (
        <>
            <AddLinkItemModal show={showModal} close={() => setShowModal(false)} addItem={addItem} />

            <div className="tile is-parent">
                <div className="card tile is-child">
                    <header className="card-header">
                        <p className="card-header-title">
                            <span className="icon">
                                <FaYoutube /></span>YouTube
                    </p>
                    </header>
                    <div className="card-content">
                        <div className={styles.outerContentArea}>
                            <div className={styles.innerContentArea}>
                                <p className="is-size-5">Paste a link to any publicly accessible YouTube video.</p>
                                <button className="button is-primary is-danger mt-3" onClick={() => setShowModal(true)}>YouTube Link</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}