import classnames from 'classnames';

import BrandLogo from '../../components/brand-logo/brand-logo';

import styles from './split.module.scss';


function SplitLayout(props) {
    return (
        <section className={classnames('container', styles.container)}>
            <div className="columns is-multiline">
                <div className={classnames('column', 'is-10', 'is-offset-1', styles.formContainer)}>
                    <div className="columns">
                        <div className={classnames('column', styles.left)}>
                            <BrandLogo inverted={true} large={true} />
                            <p className="mt-6 is-size-4">Automatically transcribe Maltese speech using <strong className="has-text-white">bil-Malti</strong>'s AI technologies. Upload your own audio and video files and get results in record time.</p>
                        </div>
                        <div className={classnames('column', styles.right)}>
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SplitLayout;
