import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { call, put } from 'redux-saga/effects';

export const FETCH_USER_SAGA = 'FETCH_USER_SAGA';
export const USER_LOGOUT_SAGA = 'USER_LOGOUT_SAGA';

export const PENDING = 'PENDING';
export const LOGGED_IN = 'LOGGED_IN';
export const LOGGED_OUT = 'LOGGED_OUT';

export const Role = {
  Admin: 'admin',
  NoCost: 'nocost'
}

export function* fetchUserSaga(action) {
  // reset the store state
  yield put({ type: 'reset/store' });
  yield put(fetchUser());
}

export function* logoutSaga(action) {
  yield call(async () => {
    await axios.get('/api/logout');
  });

  // now reset the store state
  yield put({ type: 'reset/store' });

  // fetch user to go to the correct state
  yield put(fetchUser());
}

export const fetchUser = createAsyncThunk(
  'user/fetch',
  async () => {
    try {
      const response = await axios.get('/api/user');
      return {
        loginState: LOGGED_IN,
        details: response.data
      };
    }
    catch {
      return ({
        loginState: LOGGED_OUT,
        details: {}
      })
    }
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    loginState: PENDING,
    details: {}
  },
  reducers: {
    setLoggedOut: (state, action) => {
      state.loginState = LOGGED_OUT;
    }
  },
  extraReducers: {
    [fetchUser.fulfilled]: (state, action) => {
      return action.payload;
    }
  }
})

export const { setLoggedOut } = userSlice.actions;

export default userSlice.reducer
