export default function ConsolePage(props) {
    return (
        <>
            <section className="section is-title-bar">
                <div className="container">
                    <div className="level">
                        <div className="level-left">
                            <div className="level-item">
                                <ul>
                                    {props.levels.map((level, i) => <li key={i}>{level}</li>)}
                                </ul>
                            </div>
                        </div>

                        <div className="level-right has-text-centered-mobile">
                            {props.widget}
                        </div>
                    </div>
                </div>
            </section>

            {props.children}
        </>
    )
}
