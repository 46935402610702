import { useState, useEffect, useCallback } from 'react';
import { FaEuroSign } from 'react-icons/fa';

const MIN_AMOUNT = 5;
const MAX_AMOUNT = 100;


export default function InputAmountPage({ setAmount }) {
    const [selectedAmount, setSelectedAmount] = useState('5');
    const [customAmount, setCustomAmount] = useState('10');

    const getAmount = useCallback((value) => {
        if (isNaN(value)) {
            return null;
        }
        if (value < MIN_AMOUNT || value > MAX_AMOUNT) {
            return null;
        }

        return value;
    }, []);

    const handleCustomAmountChange = (e) => {
        let amount = parseInt(e.target.value);

        if (isNaN(amount)) {
            amount = '';
        }

        setCustomAmount(amount);
    }

    useEffect(() => {
        let amount;
        if (selectedAmount === 'custom') {
            amount = parseFloat(customAmount);
        }
        else {
            amount = parseFloat(selectedAmount);
        }

        setAmount(
            getAmount(amount)
        );
    }, [selectedAmount, customAmount, setAmount, getAmount]);

    return (
        <>
            <div className="field is-horizontal">
                <div className="field-label is-normal">
                    <label className="label">Amount</label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control">
                            <div className="select is-fullwidth">
                                <select value={selectedAmount} onChange={(e) => setSelectedAmount(e.target.value)}>
                                    <option value="5.00">€5.00</option>
                                    <option value="10.00">€10.00</option>
                                    <option value="25.00">€25.00</option>
                                    <option value="50.00">€50.00</option>
                                    <option value="custom">Custom Amount</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {(selectedAmount === 'custom') && (<div className="field is-horizontal">
                <div className="field-label is-normal">
                    <label className="label">Custom</label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control">
                            <div>
                                <p className="control has-icons-left">
                                    <input className="input" type="text" value={customAmount} onChange={handleCustomAmountChange} placeholder="Custom Amount (€)" />
                                    <span className="icon is-small is-left">
                                        <FaEuroSign />
                                    </span>
                                </p>
                                <span className="is-size-7 has-text-primary">€5 minimum up to €100 in increments of €1.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)}
        </>
    )
}
