import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';


export const LoadingState = {
  Pending: 'PENDING',
  Loaded: 'LOADED',
  Failed: 'FAILED'
}


export const getStats = createAsyncThunk(
  'dashboard/getStats',
  async () => {
    try {
      const response = await axios.get('/api/dashboard/stats');
      return {
        fetchTimestamp: Date.now(),
        loadingState: LoadingState.Loaded,
        data: { ...response.data }
      }
    }
    catch {
      return {
        loadingState: LoadingState.Failed,
        data: {}
      }
    }
  }
);

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    fetchTimestamp: 0,
    loadingState: LoadingState.Pending,
    data: {}
  },
  extraReducers: {
    [getStats.fulfilled]: (state, action) => {
      return action.payload;
    }
  }
})

export default dashboardSlice.reducer;
