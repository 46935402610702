import { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { useDispatch } from 'react-redux';
import { MdMailOutline, MdLockOutline } from 'react-icons/md';

import { FETCH_USER_SAGA } from '../../store/userSlice';
import loginSchema from '../../validation/login';

import SplitLayout from '../../layouts/split/split';


function prettyMessage(msg) {
    if (msg) {
        if (msg.startsWith('"email"')) {
            msg = msg.replace('"email"', 'Email');
        }
        else if (msg.startsWith('"password"')) {
            msg = msg.replace('"password"', 'Password');
        }

        // Capitalize first letter
        return msg.charAt(0).toUpperCase() + msg.slice(1);
    }
    return msg;
}


export default function Login() {
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const dispatch = useDispatch();

    const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm({
        resolver: joiResolver(loginSchema)
    });
    const onSubmit = async (data) => {
        setShowErrorMessage(false);
        try {
            await axios.post('/api/login', {
                username: data.email,
                password: data.password
            });

            setIsLoggedIn(true);
            dispatch({ type: FETCH_USER_SAGA });
        }
        catch (e) {
            const statusCode = e.response?.status;
            setShowErrorMessage(true);
            if (statusCode === 401) {
                setErrorMessage(<>You have entered an invalid <strong>Email</strong> or <strong>Password</strong>.</>);
            }
            else {
                setErrorMessage(<>There was a problem logging you in.</>);
            }
        }
    };

    const isDisabled = isSubmitting || isLoggedIn;

    return (
        <SplitLayout>
            <h3 className="title is-3">Sign in</h3>
            <p className="description">Need a new account? <Link to="/register">Sign up</Link></p>
            <br />

            {showErrorMessage && (
                <article className="message is-danger">
                    <div className="message-body">
                        {errorMessage}
                    </div>
                </article>)}

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="field">
                    <label className="label" htmlFor="email">Email</label>
                    <div className="control has-icons-left">
                        <input disabled={isDisabled} className="input" name="email" type="text" {...register('email')} />
                        <span className="icon is-small is-left">
                            <MdMailOutline />
                        </span>
                    </div>
                    <p className="help is-danger fieldMessage">{prettyMessage(errors.email?.message)}</p>
                </div>

                <div className="field">
                    <label className="label" htmlFor="password">Password</label>
                    <div className="control has-icons-left">
                        <input disabled={isDisabled} className="input" name="password" type="password" {...register('password')} />
                        <span className="icon is-small is-left">
                            <MdLockOutline />
                        </span>
                    </div>
                    <p className="help is-danger fieldMessage">{prettyMessage(errors.password?.message)}</p>
                </div>

                <p htmlFor="password">
                    <Link to="/password/forgot">Forgot your password?</Link>
                </p>

                <br />
                <button disabled={isDisabled} type="submit" className="button is-block is-primary is-fullwidth is-medium">Login</button>
            </form>
        </SplitLayout>
    );
}