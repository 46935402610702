import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Role } from '../store/userSlice';


const AdminRoute = ({ component: Component, auth, ...rest }) => {
    const roles = useSelector((state) => state.user?.details?.roles);

    return (
        <Route {...rest} render={(props) => (
            roles.findIndex(r => r === Role.Admin) !== -1
                ? <Component {...props} />
                : <Redirect to='/' />
        )} />
    )
}

export default AdminRoute;